import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBContainer,
    MDBTypography
} from 'mdb-react-ui-kit';
import NavBar from '../Common/NavBar';

const Register = () => {
    const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/signin"
    const [userName, setName] = useState('');
    const [validName, setValidName] = useState(false);

    const [password, setPassword] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [checkMatch, setCheckMatch] = useState(false)
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRoles] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // eslint-disable-next-line
        setValidName(USER_REGEX.test(userName));
    }, [userName]);

    useEffect(() => {
        // eslint-disable-next-line
        const result = PWD_REGEX.test(password);
        console.log(password);
        console.log(result);
        setValidPass(result);
        const match = (password === matchPassword)
        setCheckMatch(match);
        console.log(matchPassword);
    }, [password, matchPassword]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validName && validPass && matchPassword) {
            try {
                fetch(process.env.REACT_APP_API_URL + `auth/register`, {
                    method: 'POST',
                    headers: { 'content-Type': 'application/json' },
                    body: JSON.stringify({
                        fullName: fullName, userName: userName, email: email, phoneNumber: phoneNumber, password: password
                    })
                }); navigate(from, { replace: true });
                setName('');
                setEmail('');
                setMatchPassword('');
                setPassword('');
            }
            catch (err) {
                if (!err?.response) {
                    setError('no server response');
                }
                else if (err?.response?.status === 409) {
                    setError('employee already exist');
                } else {
                    setError('registeration failed')
                }
            }
        }
    }

    return (
        <>
            <NavBar />
            <div className="p-5 text-center" style={{ marginTop: '58px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    Register
                </MDBTypography>
            </div><MDBContainer className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <form onSubmit={handleSubmit}>
                    <p>{error}</p>
                    <MDBRow className='mb-4'>
                        <MDBCol>
                            <MDBInput id='form3Example1' label='Full name' onChange={(e) => { setFullName(e.target.value); }} value={fullName} required />
                        </MDBCol>
                        <MDBCol>
                            <MDBInput id='form3Example2' label='User name' onChange={(e) => { setName(e.target.value); }} value={userName} required />
                        </MDBCol>
                    </MDBRow>
                    <MDBInput className='mb-4' type='email' id='email' label='Email address' onChange={(e) => { setEmail(e.target.value); }} value={email} required/>
                    <MDBInput className='mb-4' type='tel' id='email' label='Phone number' onChange={(e) => { setPhoneNumber(e.target.value); }} value={phoneNumber} required/>
                    <MDBInput className='mb-4' type='password' id='password' label='Password' onChange={(e) => { setPassword(e.target.value); }} value={password} required />
                    <MDBInput className='mb-4' type='password' id='passowrdmatch' label='Password' onChange={(e) => setMatchPassword(e.target.value)} value={matchPassword} required />

                    <MDBBtn type='submit' className='mb-4' block>
                        Sign up
                    </MDBBtn>

                    <div className='text-center'>
                        <p>
                            Not a member? <a href='login'>Login</a>
                        </p>
                    </div>
                </form>
            </MDBContainer>
        </>
    )
}

export default Register
