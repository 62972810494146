import { useState, useEffect } from "react";
import {
    MDBCol,
    MDBRow,
    MDBContainer,
} from 'mdb-react-ui-kit';
import axios from "axios";
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import AddService from "./AddService";
import Service from "../../Pricing/Service";
import ReactPaginate from 'react-paginate';

function Items({ serviceList }) {
    return (
        <>
            {serviceList.map((service) => {
                return (
                    <Service
                        key={service?.id}
                        name={service?.name}
                        description={service?.description}
                        price={service?.price}
                        Id={service?.id}
                        Image={service?.imageUrl}
                    />
                )
            })}
        </>
    );
}

export default function Services() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <MDBContainer fluid>
                    <MDBRow style={{ height: "auto" }}>
                        <MDBCol md='2'>
                            <AdminNav />
                        </MDBCol>
                        <MDBCol md='8'>
                            <h1>Services</h1>
                            <hr />
                            <MDBContainer fluid className="my-5 text-center">
                                <MDBRow>
                                    <PaginatedItems itemsPerPage={6} />
                                </MDBRow>
                            </MDBContainer>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </HasAccess>

        </>
    );
}

function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [serviceList, setServiceList] = useState([]);

    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + 'Service/GetServices',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setServiceList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    console.log(`Rendering items: ${serviceList}`);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = serviceList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(serviceList.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % serviceList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items serviceList={serviceList} />
            <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}