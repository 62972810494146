import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CarouselMobileStyle.css';
import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import NavBar from '../Common/NavBar';
import axios from "axios";
import { useTranslation } from 'react-i18next';

export default function Certificates() {
    const { t, i18n } = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    };

    const [slidesData, setSlidesData] = useState([]);

    useEffect(() => {
        getCertificates();
    }, []);

    async function getCertificates() {
        axios
            .get('Certificates/GetCertificates')
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setSlidesData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <NavBar />
            <div className="p-5 text-center bg-light " style={{ marginTop: '58px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t("certificatestitle")}
                </MDBTypography>
            </div>
            <MDBContainer fluid className='d-flex justify-content-center align-items-center'>
                <div className="CarouselMobile" style={{ minWidth: '320px', width: 'auto' }}>
                    <div className="slider-wrapper" style={{ minWidth: '320px', width: 'auto' }}>

                        <Slider {...settings}>

                            {slidesData.map((slide) =>

                                <div className="slick-slide" key={slide.id}>
                                    <img className="slick-slide-image" src={process.env.REACT_APP_PIC_URL + slide.image} />
                                </div>

                            )}

                        </Slider>
                    </div>
                </div>
            </MDBContainer>
            <div className='bottomSeparator' style={{ widht: '100%', height: 180 }}>

            </div>
        </>
    );
}