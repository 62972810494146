import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTypography,
    MDBIcon,
    MDBAccordion,
    MDBAccordionItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBCarousel,
    MDBCarouselItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import pic1 from '../../pics//01.avif'
import pic2 from '../../pics//02.avif'
import pic4 from '../../pics//04.avif'
import pic5 from '../../pics//05.avif'
import pic6 from '../../pics//06.avif'
import pic7 from '../../pics//07.avif'
import pic8 from '../../pics//08.avif'
import NavBar from '../Common/NavBar';
import { useTranslation } from "react-i18next";

export default function Programms() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <NavBar></NavBar>
            <div className="p-5 text-center" style={{ marginTop: '58px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('navbar2')}
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow className='mb-8'>

                </MDBRow>

                <div className='card_grid'>
                    <MDBRow className='row-cols-1 row-cols-md-3 g-4 .h-100'>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic1} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext7")}</MDBCardTitle>
                                    <h6>{t("mainpagetext8")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext9")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic7} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext10")}</MDBCardTitle>
                                    <h6>{t("mainpagetext11")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext12")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic2} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext13")}</MDBCardTitle>
                                    <h6>{t("mainpagetext14")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext15")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic4} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext16")}</MDBCardTitle>
                                    <h6>{t("mainpagetext17")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext18")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic5} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext19")}</MDBCardTitle>
                                    <h6>{t("mainpagetext20")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext21")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol>
                            <MDBCard className='h-100'>
                                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                    <MDBCardImage src={pic8} fluid alt='...' />
                                    <a>
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </a>
                                </MDBRipple>
                                <MDBCardBody>
                                    <MDBCardTitle>{t("mainpagetext22")}</MDBCardTitle>
                                    <h6>{t("mainpagetext23")}</h6>
                                    <MDBCardText>
                                        {t("mainpagetext24")}
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBRow className='mb-8'>

                </MDBRow>
            </MDBContainer>
        </>
    );
}