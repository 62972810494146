import React, { useState } from 'react';
import {
    MDBCol,
    MDBCard,
    MDBCardImage,
    MDBRipple,
    MDBCardBody,
} from 'mdb-react-ui-kit';
import "./Modalstyles.css";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt, BiPointer } from "react-icons/bi";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";

export default function Service(props) {
    const [modal, setModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);

    const openModal = () => {
        setModal(!modal);
    };

    const spinner = () => {
        setVideoLoading(!videoLoading);
    };
    return (
        <>
            <MDBCol md="12" lg="4" className="mb-4">
                <MDBCard className='h-100'>
                    {/*
                        <MDBRipple
                        rippleColor="light"
                        rippleTag="div"
                        className="bg-image rounded hover-zoom"
                    >
                        <MDBCardImage
                            src={process.env.REACT_APP_PIC_URL + props.Image}
                            fluid
                            style={{ width: "270px" }}
                        />
                        <a href="#!">
                            
                                    <div className="mask">
                                        <div className="d-flex justify-content-start align-items-end h-100">
                                            <h5>
                                                <span className="badge bg-primary ms-2">New</span>
                                            </h5>
                                        </div>
                                    </div>
                            

                            <div className="hover-overlay">
                                <div
                                    className="mask"
                                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                ></div>
                            </div>
                        </a>
                    </MDBRipple>
    */}

                    <div onClick={openModal} className="" style={{ cursor: "pointer" }}>
                        <div className="flex-shrink-0">
                            <MDBCardImage
                                src={process.env.REACT_APP_PIC_URL + props.Image}
                                fluid
                                style={{ width: "270px" }}
                            />
                        </div>
                        {props.Video ? (
                            modal ? (
                                <section className="modal__bg" >
                                    <div className="modal__align">
                                        <div className="modal__content" modal={modal}>
                                            <IoCloseOutline
                                                className="modal__close"
                                                arial-label="Close modal"
                                                onClick={setModal}
                                            />
                                            <div className="modal__video-align">
                                                {videoLoading ? (
                                                    <div className="modal__spinner">
                                                        <BiLoaderAlt
                                                            className="modal__spinner-style"
                                                            fadeIn="none"
                                                        />
                                                    </div>
                                                ) : null}
                                                <iframe
                                                    className="modal__video-style"
                                                    onLoad={spinner}
                                                    loading="lazy"
                                                    width="800"
                                                    height="500"
                                                    src={props.Video}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null
                        ) : null}

                    </div>
                    <MDBCardBody>
                        <h5 className="card-title mb-3">{props.name}</h5>
                        <p>{props.description}</p>
                        {/*<h6 className="mb-3">{props.price} тенге</h6>*/}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </>
    );
}