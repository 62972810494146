import React, { useState } from 'react';
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import 'react-quill/dist/quill.snow.css';
import {
    MDBBtn,
    MDBInput,
    MDBCol,
    MDBRow,
    MDBContainer,
} from 'mdb-react-ui-kit';
import axios from "axios";
import {
    useNavigate,
} from 'react-router-dom';


function DeletePost(props) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [id, setId] = useState(props.post.id);
    const [content, setContent] = useState(props.post.content);
    const [title, setTitle] = useState(props.post.title);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const navigate = useNavigate();

    const saveFile = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    const uploadFile = async (e) => {
        var FormData = require('form-data');
        console.log(file);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FormFile", file);
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'File/upload',
                headers: {
                    'Authorization': 'Bearer ' + user?.message
                },
                data: formData
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });

            //const res = await axios.post(process.env.REACT_APP_API_URL + "file/upload", formData);
            //console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    }

    console.log(props.post);


    async function DeleteBlogPost() {
        console.log("Id in delete method: ", id)
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'BlogPost/DeleteBlogPost/' + id,
                headers: {
                    'Authorization': 'Bearer ' + user?.message
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    navigate('/viewposts', {replace: true});
                    navigate(0);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        catch (err) {
            if (!err?.response) {
                console.log(err);
                console.log('no server response');
            }
            else {
                console.log('update failed')
            }
        }
    }

    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <MDBContainer fluid>
                    <MDBRow style={{ height: "auto" }}>
                        <MDBCol md='2'>
                            <AdminNav />
                        </MDBCol>
                        <MDBCol md='8'>
                            <h1>Delete Post</h1>
                            <hr />
                            <p>Are you sure you want to delete?</p>
                            <MDBBtn onClick={DeleteBlogPost}>Yes, Delete</MDBBtn>
                            <MDBBtn onClick={() => navigate(-1)}>No, Cancel</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </HasAccess>
        </>
    )
}

export default DeletePost;