import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBCarousel,
    MDBCarouselItem,
    MDBRipple,
} from 'mdb-react-ui-kit';

import pic1 from '../../pics//01.avif'
import pic2 from '../../pics/02.avif'
import pic4 from '../../pics/04.avif'
import pic5 from '../../pics/05.avif'
import pic7 from '../../pics/07.avif'
import Header from '../Common/Header'
import help from '../../pics/help.png'
import trust from '../../pics/trust.png'
import { useTranslation } from 'react-i18next';
import Testimonials from './Testimonials';
import ReactGA from 'react-ga';

export default function Home() {
    const { t, i18n } = useTranslation();
    const [basicActive, setBasicActive] = useState('tab1');
    var bg_path = process.env.REACT_APP_PIC_URL + "realityshow.png";

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const sendOutboundRITM = (event) => {
        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on RITM Checkout More',
            label: 'Home Page',
          });
    }

    return (
        <>
            <style>
                {
                    `.parentDiv {
                        margin: auto;
                        width: 40%;
                        padding: 10px;
                    }
                    .text1{
                        margin-left: 20%;
                        margin-right: 20%;
                    }
                    .card_grid{
                        margin-left: 10%;
                        margin-right: 10%;
                    }
                    .text2{
                        margin-left: 20%;
                        margin-right: 20%;
                    }
                    .image{
                        width: 100%;
                    }
                    .rounded-t-5 {
                        border - top - left - radius: 0.5rem;
                    border-top-right-radius: 0.5rem;
                    }
                    @media (min-width: 992px) {
                        .rounded - tr - lg - 0 {
                            border - top - right - radius: 0;
                        }

                        .rounded-bl-lg-5 {
                            border - bottom - left - radius: 0.5rem;
                        }
                    }
                    @media (min-width: 992px) {
                        #cta - img - nml - 50 {
                        margin - left: 50px;
                        }
                    }
                    .rounded-t-5 {
                        border - top - left - radius: 0.5rem;
                    border-top-right-radius: 0.5rem;
                    }
                    
                    @media (min-width: 992px) {
                    .rounded - tr - lg - 0 {
                        border - top - right - radius: 0;
                    }
                    
                    .rounded-bl-lg-5 {
                        border - bottom - left - radius: 0.5rem;
                    }
                    }
                    `
                }
            </style>
            <Header />
            <MDBContainer>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow style={{ marginTop: "140px" }}>
                    <div id="preview" class="preview">
                        <div style={{ display: "none" }}></div>
                        <div>
                            <div data-draggable="true" class="" style={{ position: "relative", marginTop: "-150px", marginLeft: "100px" }} draggable="false">
                                <section draggable="false" class="container pt-5" data-v-271253ee="">
                                    <section class="mb-10 text-center text-lg-start">
                                        <div class="px-4 py-5 px-md-5">
                                            <div class="row gx-lg-5 align-items-center">
                                                <div class="col-lg-4 mb-5 mb-lg-0">
                                                    <a class="btn btn-success btn-lg py-3 px-5 mb-2 me-2" href="realityshow" role="button"
                                                        aria-controls="#picker-editor" draggable="false">{t('home_page_ffw_button')}</a>
                                                </div>
                                                <div class="col-lg-8 mb-5 mb-lg-0"> <img
                                                    src={bg_path}
                                                    class="w-100 rounded-4 shadow-5" alt="" aria-controls="#picker-editor" draggable="false" /> </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </MDBRow>

                <MDBRow className='mb-6' style={{ marginTop: "-50px" }}>
                    <div data-draggable="true" class="" style={{ position: "relative", marginTop: "-100px", marginLeft: "100px" }}>
                        <section draggable="false" class="container pt-4" data-v-271253ee="">
                            <section class="mb-10">
                                <div class="row gx-0">
                                    <div class="col-lg-5 mb-4 mb-md-0">
                                        <div class="d-flex py-md-5"> <img src={process.env.REACT_APP_PIC_URL + "matthijs-smit-bRG2C0FAQEY-unsplash.jpg"}
                                            class="w-100 rounded-5 shadow-3-strong" id="cta-img-nml-50" style={{ zIndex: "10" }} alt=""
                                            aria-controls="#picker-editor" /> </div>
                                    </div>
                                    <div class="col-lg-7 mb-4 mb-md-0">
                                        <div class="h-100 rounded-6 p-4 ps-lg-5 d-flex align-items-center" style={{ backgroundColor: "" }}>
                                            <div class="ps-lg-5">
                                                <h2 class="fw-bold mb-4">RITM Therapy</h2>
                                                {/*
                                                 <div class="row mb-2" style={{ fontSize: "20px" }}>
                                                    <div class="col-lg-4">
                                                        <p><i class="fas fa-check-circle me-2"></i><span>Best team</span></p>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <p><i class="fas fa-check-circle me-2"></i><span>Best quality</span></p>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <p> <i class="fas fa-check-circle me-2"></i><span>Best experience</span> </p>
                                                    </div>
                                                </div>
                                                <p style={{ fontSize: "20px" }}>Duis sagittis, turpis in ullamcorper venenatis, ligula nibh porta dui, sit amet rutrum enim massa
                                                    in ante. Curabitur in justo at lorem laoreet ultricies. Nunc ligula felis, sagittis eget nisi
                                                    vitae, sodales vestibulum purus. Vestibulum nibh ipsum, rhoncus vel sagittis nec, placerat vel
                                                    justo. Duis faucibus sapien eget tortor finibus, a eleifend lectus dictum. Cras tempor convallis
                                                    magna id rhoncus. Suspendisse potenti. Nam mattis faucibus imperdiet. Proin tempor lorem at neque
                                                    tempus aliquet. Phasellus at ex volutpat, varius arcu id, aliquam lectus. Vestibulum mattis felis
                                                    quis ex pharetra luctus. Etiam luctus sagittis massa, sed iaculis est vehicula ut.</p>
                                                    <br />

                                                */}
                                                <a class="btn btn-success py-3 px-5 mb-2 me-2" href="ritmtherapy" role="button"
                                                    aria-controls="#picker-editor" draggable="false" onClick={sendOutboundRITM}>{t('home_page_ffw_button')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </MDBRow>

                <MDBRow className='mb-6' style={{ marginTop: "-100px" }}>
                    <div id="preview" class="preview">
                        <div style={{ display: "none" }}></div>
                        <div>
                            <div data-draggable="true" class="" style={{ position: "relative" }}>
                                <section draggable="false" class="container pt-5" data-v-271253ee="">
                                    <section class="mb-10">
                                        <div class="row gx-0">
                                            <div class="col-lg-7 mb-4 mb-md-0">
                                                <div class="h-100 rounded-6 p-4 ps-lg-5 d-flex align-items-center" style={{ backgroundColor: "" }}>
                                                    <div class="ps-lg-5">
                                                        <h2 class="fw-bold mb-4">{t('mainpagetext1')}</h2>
                                                        <p style={{ fontSize: "20px" }}>{t('mainpagetext2')}</p>
                                                        {/* 
                                                        <div class="row mb-2">
                                                            <div class="col-lg-4">
                                                                <p><i class="fas fa-check-circle me-2"></i><span>Best team</span></p>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <p><i class="fas fa-check-circle me-2"></i><span>Best quality</span></p>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <p> <i class="fas fa-check-circle me-2"></i><span>Best experience</span> </p>
                                                            </div>
                                                        </div>
                                                        <p>Duis sagittis, turpis in ullamcorper venenatis, ligula nibh porta dui, sit amet rutrum enim massa
                                                            in ante. Curabitur in justo at lorem laoreet ultricies. Nunc ligula felis, sagittis eget nisi
                                                            vitae, sodales vestibulum purus. Vestibulum nibh ipsum, rhoncus vel sagittis nec, placerat vel
                                                            justo. Duis faucibus sapien eget tortor finibus, a eleifend lectus dictum. Cras tempor convallis
                                                            magna id rhoncus. Suspendisse potenti. Nam mattis faucibus imperdiet. Proin tempor lorem at neque
                                                            tempus aliquet. Phasellus at ex volutpat, varius arcu id, aliquam lectus. Vestibulum mattis felis
                                                            quis ex pharetra luctus. Etiam luctus sagittis massa, sed iaculis est vehicula ut.</p>
                                                    */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 mb-4 mb-md-0">
                                                <div class="d-flex py-md-5"> <img src={help}
                                                    class="w-100 rounded-5 shadow-3-strong" id="cta-img-nml-50" style={{ zIndex: "10" }} alt=""
                                                    aria-controls="#picker-editor" /> </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                            <div data-draggable="true" class="" style={{ position: "relative", marginTop: "-100px", marginLeft: "100px" }}>
                                <section draggable="false" class="container pt-4" data-v-271253ee="">
                                    <section class="mb-10">
                                        <div class="row gx-0">
                                            <div class="col-lg-5 mb-4 mb-md-0">
                                                <div class="d-flex py-md-5"> <img src={trust}
                                                    class="w-100 rounded-5 shadow-3-strong" id="cta-img-nml-50" style={{ zIndex: "10" }} alt=""
                                                    aria-controls="#picker-editor" /> </div>
                                            </div>
                                            <div class="col-lg-7 mb-4 mb-md-0">
                                                <div class="h-100 rounded-6 p-4 ps-lg-5 d-flex align-items-center" style={{ backgroundColor: "" }}>
                                                    <div class="ps-lg-5">
                                                        <h2 class="fw-bold mb-4">{t('mainpagetext3')}</h2>
                                                        <p style={{ fontSize: "20px" }}>{t('mainpagetext4')}</p>
                                                        {/* 
                                                        <div class="row mb-2">
                                                            <div class="col-lg-4">
                                                                <p><i class="fas fa-check-circle me-2"></i><span>Best team</span></p>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <p><i class="fas fa-check-circle me-2"></i><span>Best quality</span></p>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <p> <i class="fas fa-check-circle me-2"></i><span>Best experience</span> </p>
                                                            </div>
                                                        </div>
                                                        <p>Duis sagittis, turpis in ullamcorper venenatis, ligula nibh porta dui, sit amet rutrum enim massa
                                                            in ante. Curabitur in justo at lorem laoreet ultricies. Nunc ligula felis, sagittis eget nisi
                                                            vitae, sodales vestibulum purus. Vestibulum nibh ipsum, rhoncus vel sagittis nec, placerat vel
                                                            justo. Duis faucibus sapien eget tortor finibus, a eleifend lectus dictum. Cras tempor convallis
                                                            magna id rhoncus. Suspendisse potenti. Nam mattis faucibus imperdiet. Proin tempor lorem at neque
                                                            tempus aliquet. Phasellus at ex volutpat, varius arcu id, aliquam lectus. Vestibulum mattis felis
                                                            quis ex pharetra luctus. Etiam luctus sagittis massa, sed iaculis est vehicula ut.</p>
                                                    */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow>
                    <div id="preview" class="preview">
                        <div style={{ display: 'none' }}></div>
                        <div>
                            <div data-draggable="true" class="" style={{ position: 'relative', marginTop: "-200px" }}>
                                <section draggable="false" class="container pt-5" data-v-271253ee="">
                                    <section class="mb-10 text-center">
                                        <h2 class="fw-bold mb-9 text-center">{t("mainpagetext25")}</h2>
                                        <div class="row gx-lg-5">
                                            <div class="col-lg-4 col-md-12 mb-8 mb-lg-0">
                                                <div class="card shadow-2-strong h-100">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{t("mainpagetab1")}</h5>
                                                        <p class="card-text">
                                                            {t("mainpagetab1content")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 mb-8 mb-lg-0">
                                                <div class="card shadow-2-strong h-100">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{t("mainpagetab2")}</h5>
                                                        <p class="card-text">
                                                            {t("mainpagetab2content")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 mb-8 mb-lg-0">
                                                <div class="card shadow-2-strong h-100">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{t("mainpagetab3")}</h5>
                                                        <p class="card-text">
                                                            {t("mainpagetab3content")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow>
                    <div id="preview" class="preview">
                        <div style={{ display: "none" }}></div>
                        <div>
                            <div data-draggable="true" class="" style={{ position: "relative", marginTop: "-150px" }} draggable="false">
                                <section draggable="false" class="container pt-5" data-v-271253ee="">
                                    <section class="mb-10 text-center text-lg-start">
                                        <div class="px-4 py-5 px-md-5">
                                            <div class="row gx-lg-5 align-items-center">
                                                <div class="col-lg-6 mb-5 mb-lg-0">
                                                    <h2 class="my-5 display-5 fw-bold ls-tight">
                                                        <span class="text-success">{t('home_page_ffw_title')}</span> </h2> <a
                                                            class="btn     btn-success btn-lg py-3 px-5 mb-2 me-2" href="fastforword" role="button"
                                                            aria-controls="#picker-editor" draggable="false">{t('home_page_ffw_button')}</a> <a
                                                                class="btn   btn-link text-success btn-lg py-3 px-5 mb-2 me-2 ripple-surface-success"
                                                                data-ripple-color="success" href="" role="button" aria-controls="#picker-editor" draggable="false"
                                                                style={{ minWidth: "auto" }}></a>
                                                </div>
                                                <div class="col-lg-6 mb-5 mb-lg-0"> <img
                                                    src="https://cdn.carnegielearning.com/cli_theme/_img/www-2020/ffw/ffw-product-hero.webp"
                                                    class="w-100 rounded-4 shadow-5" alt="" aria-controls="#picker-editor" draggable="false" /> </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow className='mb-4'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className="ratio ratio-16x9" style={{ maxHeight: '400px', maxWidth: '640px' }}>
                            <iframe
                                src={t('homeytlink')}
                                title="YouTube video"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow className='mb-4'>
                </MDBRow>
                <MDBRow className='row-cols-1 row-cols-md-3 g-4 .h-100'>
                    <MDBCol md="12" lg="4" className="mb-4">
                        <MDBCard className='h-100' alignment='center'>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                <MDBCardImage src={pic1} fluid alt='...' />
                                <a href="page/0">
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>{t("mainpagetext7")}</MDBCardTitle>
                                <h6>{t("mainpagetext8")}</h6>
                                <MDBCardText>
                                    {t("mainpagetext9")}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="12" lg="4" className="mb-4">
                        <MDBCard className='h-100' alignment='center'>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                <MDBCardImage src={pic7} fluid alt='...' />
                                <a href="page/1">
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>{t("mainpagetext10")}</MDBCardTitle>
                                <h6>{t("mainpagetext11")}</h6>
                                <MDBCardText>
                                    {t("mainpagetext12")}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="12" lg="4" className="mb-4">
                        <MDBCard className='h-100' alignment='center'>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                <MDBCardImage src={pic2} fluid alt='...' />
                                <a href="page/2">
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>{t("mainpagetext13")}</MDBCardTitle>
                                <h6>{t("mainpagetext14")}</h6>
                                <MDBCardText>
                                    {t("mainpagetext15")}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="12" lg="4" className="mb-4">
                        <MDBCard className='h-100' alignment='center'>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                <MDBCardImage src={pic4} fluid alt='...' />
                                <a href="page/3">
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>{t("mainpagetext16")}</MDBCardTitle>
                                <h6>{t("mainpagetext17")}</h6>
                                <MDBCardText>
                                    {t("mainpagetext18")}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="12" lg="4" className="mb-4">
                        <MDBCard className='h-100' alignment='center'>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                <MDBCardImage src={pic5} fluid alt='...' />
                                <a href="page/4">
                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                </a>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>{t("mainpagetext19")}</MDBCardTitle>
                                <h6>{t("mainpagetext20")}</h6>
                                <MDBCardText>
                                    {t("mainpagetext21")}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mb-7'>

                </MDBRow>
                <MDBRow className='mb-7'>
                    <Testimonials />
                </MDBRow>
                <MDBRow className='mb-7'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.978919597733!2d76.89279857666283!3d43.20993148098645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f2370a11939%3A0x410268c29b65889d!2z0JvQvtCz0L7Qv9C10LQg0JvQtdC90LQg0JrQsNC30LDRhdGB0YLQsNC9!5e0!3m2!1sru!2skz!4v1696324840343!5m2!1sru!2skz" width="auto" height="auto" style={{ minHeight: "400px", minWidth: '300px', border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </MDBRow>
                <MDBRow className='mb-5'>

                    <div id="preview" class="preview">
                        <div style={{ display: "none" }}></div>
                        <div>
                            <div data-draggable="true" class="" style={{ position: "relative" }} draggable="false">
                                <section draggable="false" class="container pt-5" data-v-271253ee="">
                                    <section class="mb-10">
                                        <div class="card mb-3">
                                            <div class="row g-0 d-flex align-items-center">
                                                <div class="col-lg-4 d-none d-lg-flex">
                                                    {/*<img
                                                        src="https://images.unsplash.com/photo-1687720657052-c026be7f388c?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTAzfHxvbmxpbmUlMjBzdG9yZXxlbnwwfDF8MHx8fDI%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                                                        alt="" class="w-100 rounded-t-5 rounded-tr-lg-0 rounded-bl-lg-5" aria-controls="#picker-editor"
                                                draggable="false" />*/}
                                                    <MDBCarousel>
                                                        <MDBCarouselItem
                                                            className='w-100 d-block rounded-tr-lg-0 rounded-bl-lg-5'
                                                            itemId={1}
                                                            src='https://cdn11.bigcommerce.com/s-ghsrgu/images/stencil/1280x1280/products/181/5118/ark-z-vibe__26853.1601603967.jpg?c=2'
                                                            alt='...'
                                                        />
                                                        <MDBCarouselItem
                                                            className='w-100 d-block rounded-tr-lg-0 rounded-bl-lg-5'
                                                            itemId={2}
                                                            src='https://cdn11.bigcommerce.com/s-ghsrgu/images/stencil/600x600/products/260/3309/arks-z-vibe-tip-kit__25730.1580320991.jpg?c=2'
                                                            alt='...'
                                                        />
                                                        <MDBCarouselItem
                                                            className='w-100 d-block rounded-tr-lg-0 rounded-bl-lg-5'
                                                            itemId={3}
                                                            src='https://i0.wp.com/shop.logopedland.kz/wp-content/uploads/2019/02/mustache-lip-whistles.png?fit=772%2C696&ssl=1'
                                                            alt='...'
                                                        />
                                                    </MDBCarousel>

                                                </div>
                                                <div class="col-lg-8">
                                                    <div class="card-body py-5 px-md-5">
                                                        <h2 class="fw-bold mb-4">Магазин для логопедов</h2>
                                                        <div class="row mb-3">
                                                            <div class="col-lg-4 mb-2">
                                                                <p> <i class="fas fa-check-circle text-dark me-2"></i><span>ЛОГОПЕДИЧЕСКИЕ ЗОНДЫ</span> </p>
                                                            </div>
                                                            <div class="col-lg-4 mb-2">
                                                                <p> <i class="fas fa-check-circle text-dark me-2"></i><span>Ark's Z-Vibe</span> </p>
                                                            </div>
                                                            <div class="col-lg-4 mb-2">
                                                                <p> <i class="fas fa-check-circle text-dark me-2"></i><span>ПОСОБИЯ и КНИГИ</span> </p>
                                                            </div>
                                                            <div class="col-lg-4 mb-2">
                                                                <p> <i class="fas fa-check-circle text-dark me-2"></i><span>материал в помощь родителям и специалистам</span> </p>
                                                            </div>
                                                            <div class="col-lg-7 mb-2">
                                                                <p> <i class="fas fa-check-circle text-dark me-2"></i><span>ОБОРУДОВАНИЕ необходимое для логопедического кабинета и заняти</span> </p>
                                                            </div>
                                                        </div> <a type="button" href="https://shop.logopedland.kz/" class="btn   btn-dark btn-lg px-5" aria-controls="#picker-editor">Купить</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>

                </MDBRow>
            </MDBContainer>
        </>
    );
}