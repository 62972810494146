import React, { useState, useEffect } from 'react';
import {
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownMenu,
} from 'mdb-react-ui-kit';
import logo from '../../pics/logo.png';
import { useTranslation } from 'react-i18next';

const langs = {
    en: 'English',
    ru: 'Русский',
    kz: 'Қазақша'
}

export default function NavBar() {
    const [navbar, setNavbar] = useState(false);
    const [showNavRight, setShowNavRight] = useState(true);
    const [showBasic, setShowBasic] = useState(false);
    const { t, i18n } = useTranslation();

    //navbar scroll changeBackground function
    const changeBackground = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 100) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    return (
        <>
            <MDBNavbar expand='lg' light sticky='true' style={{ backgroundColor: '#52bf90' }}>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='/'>
                        <img
                            src={logo}
                            height='30'
                            alt=''
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>
                                    {t("navbar1")}
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/fastforword' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar8")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/posts' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar3")}</MDBNavbarLink>
                            </MDBNavbarItem>
                            {/*
                            <MDBNavbarItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle tag='a' className='nav-link' role='button' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>
                                        {t("navbar2")}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem link href='/programms' style={{ color: "#faf0e6", textDecoration: "none" }}>{t("navbar2")}</MDBDropdownItem>
                                        <MDBDropdownItem link href='/posts' style={{ color: "#faf0e6", textDecoration: "none" }}>{t("navbar3")}</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavbarItem>
                            */ }

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/employees' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar4")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/certificates' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar5")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/ServiceList' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar6")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/reviews' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar7")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/contacts' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar9")}</MDBNavbarLink>
                            </MDBNavbarItem>

                        </MDBNavbarNav>

                        <div className='d-flex input-group w-auto'>
                            <MDBNavbarItem className='d-flex justify-content-end'>
                                <a href="https://wa.me/77084892614" style={{ color: "#faf0e6", marginTop: "8px", marginRight: "10px" }}>
                                    <MDBIcon fab icon="whatsapp" />
                                </a>
                                <MDBNavbarLink href={`tel:${t('phone2')}`} style={{ color: "#faf0e6", fontSize: "17px" }}>
                                    7(708)4892614
                                </MDBNavbarLink>
                                <MDBDropdown>
                                    <MDBDropdownToggle color='link' style={{ color: "#faf0e6", fontSize: "15px" }}><i class={t('flag_code')}></i>{t("lang")}</MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {Object.keys(langs).map((lang) => (
                                            <MDBDropdownItem link type='submit' key={lang} onClick={() => i18n.changeLanguage(lang)} disabled={i18n.resolvedLanguage === lang} style={{ color: 'black' }}>
                                                {langs[lang]}
                                            </MDBDropdownItem>
                                        ))}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavbarItem>
                        </div>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </>
    );
}