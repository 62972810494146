import React, { useState, useMemo, useRef } from 'react';
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  MDBBtn,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBContainer,
} from 'mdb-react-ui-kit';
import axios from "axios";
import {
  useNavigate,
} from 'react-router-dom';

async function imageHandler() {
  const input = document.createElement('input');
  const token = JSON.parse(localStorage.getItem("user"));

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const fileName = input.files[0].name;
    var FormData = require('form-data');
    console.log(file);
    const formData = new FormData();
    formData.append("FileName", fileName);
    formData.append("FormFile", file);

    // Save current cursor state
    const range = this.quill.getSelection(true);

    // Insert temporary loading placeholder image
    this.quill.insertEmbed(range.index, "image", process.env.REACT_APP_PIC_URL + `placeholder.png`);

    // Move cursor to right side of image (easier to continue typing)
    this.quill.setSelection(range.index + 1);

    try {
      var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'File/upload',
        headers: {
          'Authorization': 'Bearer ' + token?.message
        },
        data: formData
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          console.log("file: " + fileName);
          const res = process.env.REACT_APP_PIC_URL + fileName;
          console.log("path: " + res);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (ex) {
      console.log(ex);
    }

    console.log("file: " + fileName);
    var res = process.env.REACT_APP_PIC_URL + fileName;
    console.log("path: " + res);

    // Remove placeholder image
    this.quill.deleteText(range.index, 1);

    // Insert uploaded image
    // this.quill.insertEmbed(range.index, 'image', res.body.image);
    this.quill.insertEmbed(range.index, 'image', res);
  };
}

function AdminPosts() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const navigate = useNavigate();

  const saveFile = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  const uploadFile = async (e) => {
    var FormData = require('form-data');
    console.log(file);
    const formData = new FormData();
    formData.append("FileName", fileName);
    formData.append("FormFile", file);
    try {
      var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'File/upload',
        headers: {
          'Authorization': 'Bearer ' + user?.message
        },
        data: formData
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

      //const res = await axios.post(process.env.REACT_APP_API_URL + "file/upload", formData);
      //console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  }


  async function CreateBlogPost() {
    try {
      var data = JSON.stringify({
        "title": title,
        "content": content,
        "imageUrl": fileName,
        "descriptionTitle": descriptionTitle,
        "descriptionContent": descriptionContent
      });

      var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'BlogPost/createblogpost',
        headers: {
          'Authorization': 'Bearer ' + user?.message,
          'Content-Type': 'application/json'
        },
        data: data
      };
      console.log(config);

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          uploadFile();
          navigate('/viewposts', { replace: true });
          navigate(0);
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log('working');
    }
    catch (err) {
      if (!err?.response) {
        console.log(err);
        console.log('no server response');
      }
      else {
        console.log('update failed')
      }
    }
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
        ['code-block']
      ],
      handlers: {
        image: imageHandler
      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }), [])

  return (
    <>
      <HasAccess
        roles={["ADMIN", "OWNER"]}
        renderAuthFailed={
          <div style={{ height: '100vh' }}>
            <h1>Unauthorized</h1>
          </div>}>
        <MDBContainer fluid>
          <MDBRow style={{ height: "auto" }}>
            <MDBCol md='2'>
              <AdminNav />
            </MDBCol>
            <MDBCol md='8'>
              <h1>Welcome {user?.userName}</h1>
              <hr />
              <ReactQuill
                onChange={setContent}
                placeholder="Content goes here..."
                modules={modules}
              />
            </MDBCol>
            <MDBCol md='2'>
              <div style={{ height: '100px' }}></div>
              <MDBInput className='mb-4' type='text' id='title' label='Page Title' onChange={(e) => { setTitle(e.target.value) }} />
              <MDBInput className='mb-4' type='text' id='descriptionTitle' label='Description Title' onChange={(e) => { setDescriptionTitle(e.target.value) }} />
              <MDBInput className='mb-4' type='text' id='descriptionContent' label='Description Content' onChange={(e) => { setDescriptionContent(e.target.value) }} />
              <MDBInput type="file" onChange={saveFile} />
              <MDBBtn type='submit' block onClick={CreateBlogPost}>
                Submit
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </HasAccess>
    </>
  )
}

export default AdminPosts;