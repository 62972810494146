import { useState, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import axios from "axios";
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import User from "./User";

export default function Users() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getUsers();
    }, []);


    const API = axios.create({
        baseURL: process.env.REACT_APP_API_URL + 'admin/',
        withCredentials: true,
    })
    const headers = { Authorization: `Bearer ${user?.message}` };

    function handleEditClick() {

        console.log('edit clicked');
    }

    async function getUsers() {
        try {
            const res = await API.get('getusers', { headers })
                .then(res => {
                    setIsLoading(false);
                    setUsers(res.data);
                    return res?.data;
                })
            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                setError('no server response');
            }
            else {
                setError('login failed')
            }
        }
    }
    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <body>
                    <div className="d-flex flex-row" style={{ width: "100%" }}>
                        <div class="d-flex align-items-start flex-column">
                            <div className="align-self-start" style={{ marginLeft: "0px", height: "100vh" }}>
                                <AdminNav />
                            </div>
                        </div>
                        <div class="d-flex align-items-start flex-column" style={{ height: '200px', marginRight: '0px', width: "100%" }}>
                            <div className="mb-auto p-2" style={{ height: '200px', marginRight: '0px' }}>
                                <h1>Welcome {user?.userName}</h1>
                            </div>
                            <div className="mb-auto p-2" style={{ width: '100%', margin: 'auto', borderColor: 'black' }}>
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Full Name</th>
                                            <th scope='col'>User Name</th>
                                            <th scope='col'>Email</th>
                                            <th scope='col'>Phone Number</th>
                                            <th scope='col'>Action</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {users?.map((user) => {
                                            return (
                                                <User
                                                    key={user?.id}
                                                    FullName={user?.fullName}
                                                    UserName={user?.userName}
                                                    Email={user?.email}
                                                    PhoneNumber={user?.phoneNumber}
                                                />
                                            )
                                        })}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </div>
                </body>
            </HasAccess>

        </>
    );
}