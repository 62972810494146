import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBTypography,
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBCardBody,
    MDBCol
} from 'mdb-react-ui-kit';
import { useEffect } from 'react';
import NavBar from '../Common/NavBar';
import axios from 'axios';
import EmployeeCard from './EmployeeCard';
import { useTranslation } from 'react-i18next';

export default function App() {
    const { t, i18n } = useTranslation();
    const [employees, setEmployees] = React.useState([]);

    useEffect(() => {
        getEmployees();
    }, []);

    async function getEmployees() {
        axios
            .get('Employee/GetEmployees')
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setEmployees(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            <NavBar />
            <div className="p-5 text-center" style={{ marginTop: '58px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('navbar4')}
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow className='justify-content-center'>
                    {
                        <MDBCol md="9" lg="7" xl="7" className="mt-5">
                            <MDBCard style={{ borderRadius: '15px' }} shadow='5'>
                                <MDBCardBody className="p-4">
                                    <div className="d-flex text-black">
                                        <div className="flex-shrink-0">
                                            <MDBCardImage
                                                style={{ width: '180px', borderRadius: '10px' }}
                                                src={process.env.REACT_APP_PIC_URL + "2S2A5681.jpg"}
                                                alt='Generic placeholder image'
                                                fluid />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <MDBCardTitle>Балкибекова Венера Серикпаевна</MDBCardTitle>
                                            <MDBCardText>Руководитель центра. Магистр логопедии, нейропсихолог, специалист DIR Floortime, член Международной организации ASHA.</MDBCardText>
                                        </div>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    }
                </MDBRow>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow className='justify-content-center'>
                    {employees?.map((employee) => {
                        return (
                            <EmployeeCard
                                key={employee?.employeeID}
                                id={employee?.employeeID}
                                userId={employee?.userId}
                                FullName={employee?.fullName}
                                Title={employee?.title}
                                Email={employee?.email}
                                PhoneNumber={employee?.phone}
                                Image={employee?.image}
                                Video={employee?.video}
                            />
                        )
                    })}
                </MDBRow>
            </MDBContainer>
            <div className='bottomSeparator' style={{ widht: '100%', height: 180 }}>

            </div>
        </>
    );
}