import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBTextArea
  } from 'mdb-react-ui-kit';
import axios from "axios";

function AddService() {
    const [centredModal, setCentredModal] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [file, setFile] = useState('placeholder.png');
    const [fileName, setFileName] = useState();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const toggleShow = () => setCentredModal(!centredModal);

    const saveFile = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    const uploadFile = async (e) => {
        var FormData = require('form-data');
        console.log(file);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FormFile", file);
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'File/upload',
                headers: {
                    'Authorization': 'Bearer ' + user?.message
                },
                data: formData
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });

            //const res = await axios.post("https://api.logopedland.kz/api/file/upload", formData);
            //console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    }

    async function addService(name, description, price, fileName) {
        var data = JSON.stringify({
            "name": name,
            "description": description,
            "price": price,
            "imageUrl": fileName
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + 'Service/CreateService',
            headers: {
                'Authorization': 'Bearer ' + user?.message,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                uploadFile();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>
            <MDBBtn onClick={toggleShow}>Add</MDBBtn>

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Service</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput wrapperClass='mb-4 mx-1' label='Name' id='form1' type='text' size='lg' value={name} onChange={(e) => { setName(e.target.value) }} />
                            <MDBInput wrapperClass='mb-4 mx-1' label='Price' id='form3' type='number' size='lg' value={price} onChange={(e) => { setPrice(e.target.value) }} />
                            <MDBTextArea label='Description' id='Description' rows={4} value={description} onChange={(e) => { setDescription(e.target.value) }}/>
                            <br />
                            <MDBInput type="file" onChange={saveFile} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={(e) => {
                                e.preventDefault();
                                addService(name, description, price, fileName);
                            }}>Add</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );

}

export default AddService;