import { useState, useEffect } from "react";
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import axios from "axios";
import { Table } from "antd";
import {
    IdcardOutlined,
    UnorderedListOutlined,
    MailOutlined,
    PhoneOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";

export default function User(props) {

    return (
        <>
            <tr>
                <th scope='row'>{props.FullName}</th>
                <td>{props.UserName}</td>
                <td>{props.Email}</td>
                <td>{props.PhoneNumber}</td>
                <td>
                    <MDBBtn color='link' rounded size='sm'>
                        Edit
                    </MDBBtn>
                </td>
            </tr>
        </>
    );
}