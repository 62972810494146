const questions = [
    {
        question: 'What is the capital of India?',
        options: ['Mumbai', 'Pune', 'Nagpur', 'Delhi'],
        answer: 'Delhi',
        id:1
    },    
    {
      question: 'Who is the Prime Minister of India?',
      options: ['Rahul Gandhi', 'Nitin Gadkari', 
             'Narendra Modi', 'Sharad Pawar'],
      answer: 'Narendra Modi',
      id:2
    },
 
      {
        question: 'When India got independence?',
        options: ['1950', '1947', '1930', '1945'],
        answer: '1947',
        id:3
      },
 
  ];
  export default questions;