import { useState, useEffect } from "react";
import axios from "axios";
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import EmployeeCard from "./EmployeeCard";
import { useLocation, useNavigate } from "react-router-dom";
import AddEmployee from "./AddEmployee";
import {
    MDBCol,
    MDBRow,
    MDBContainer,
} from 'mdb-react-ui-kit';

export default function EmployeeList() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState('');
    const [employees, setEmployees] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    useEffect(() => {
        getEmployees();
    }, []);

    const API = axios.create({
        baseURL: process.env.REACT_APP_API_URL + 'Employee/',
        withCredentials: true,
    })
    const headers = { Authorization: `Bearer ${user?.message}` };

    async function getEmployees() {
        try {
            const res = await API.get('GetEmployees', { headers })
                .then(res => {
                    setEmployees(res.data);
                    return res?.data;
                })
            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                setError('no server response');
            }
            else {
                setError('login failed')
            }
        }
    }

    //This code will update the employee information using the axios post method and the endpoint in the backend. 
    //The data is converted into JSON format and is sent to the backend. If it is successful, it will navigate to the employee list page.

    async function updateEmployee(id, fullName, email, title, PhoneNumber, image) {
        try {
            var data = JSON.stringify({
                "employeeID": id,
                "fullName": fullName,
                "email": email,
                "phone": PhoneNumber,
                "title": title,
                "salary": "1000",
                "image": image
            });

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Employee/updateemployee',
                headers: {
                    'Authorization': 'Bearer ' + user?.message,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    const updatedEmployee = employees.map((employee) => {
                        if (employee.employeeID === id) {
                            return {
                                ...employee,
                                fullName: fullName,
                                email: email,
                                phone: PhoneNumber,
                                title: title,
                                image: image
                            };
                        }
                        window.location.reload(true);
                        return updatedEmployee;
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                console.log('no server response');
            }
            else {
                console.log('update failed')
            }
        }
    }

    async function deleteEmployee(id) {
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Employee/deleteemployee/' + id,
                headers: {
                    'Authorization': 'Bearer ' + user?.message,
                    'Content-Type': 'application/json'
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    window.location.reload(true);
                })
                .catch(function (error) {
                    console.log(error);
                });

            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                console.log('no server response');
            }
            else {
                console.log('delete failed')
            }
        }
    }
    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <MDBContainer fluid>
                    <MDBRow style={{ height: "auto" }}>
                        <MDBCol md='2'>
                            <AdminNav />
                        </MDBCol>
                        <MDBCol md='8'>
                            <h1>Welcome {user?.userName}</h1>
                            <AddEmployee />
                            <hr />
                            <div className="mb-auto p-2" style={{ width: '100%', margin: 'auto', borderColor: 'black', height: 'auto' }}>
                                {employees?.map((employee) => {
                                    return (
                                        <EmployeeCard
                                            key={employee?.employeeID}
                                            id={employee?.employeeID}
                                            userId={employee?.userId}
                                            FullName={employee?.fullName}
                                            Title={employee?.title}
                                            Email={employee?.email}
                                            PhoneNumber={employee?.phone}
                                            Image={employee?.image}
                                            updateEmployee={updateEmployee}
                                            deleteEmployee={deleteEmployee}
                                        />
                                    )
                                })}
                            </div>
                            <div className='bottomSeparator' style={{ widht: '100%', height: 180 }}>

                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </HasAccess>

        </>
    );
}