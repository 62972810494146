import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBTypography,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';
import Navbar from "../Common/NavBar";
import { useTranslation } from "react-i18next";

function VideoTutorials() {
    const { t, i18n } = useTranslation();

    const onButtonClick = () => {
        const pdfUrl = "Foundation_instruction_Ipad.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Foundation_instruction_Ipad.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Navbar />
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('video_tutorials')}
                </MDBTypography>
            </div>
            <MDBContainer className="my-5 text-center">
                <MDBRow>
                    <MDBCol>
                        <p style={{fontSize: "20px"}}>{t('foundation_pdf_download')}</p>
                    </MDBCol>
                    <MDBCol>
                    </MDBCol>
                    <MDBCol>
                        <div style={{ textAlign: "end" }}>
                            <MDBBtn onClick={onButtonClick}><MDBIcon fas icon="file-download" /> {t('pdf_download_btn')}</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials1_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Yq1CCUM1Z28?si=G8Cn2-1gFy8H6yHa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials2_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/YK-D7YW2bSs?si=Qm8gq3ySUX9LZVxK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials3_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/wBbtg9O361k?si=UzGVEnWLtQgDFsm4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials4_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/_9wBu5Y_Y_0?si=F_CHJ7AXtDJleZWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials5_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/9OYJxx8jjuU?si=HJczTSlBiN2vpN5X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials6_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/eYmn58pGjVU?si=0MBeeMhgsqjrN6rE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol style={{ marginTop: "147px" }}>
                        <h3>{t('video_tutorials7_title')}</h3>
                    </MDBCol>
                    <MDBCol>
                        <div>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/JrAt5WmdD8c?si=JN7Yw5WRM8ODYw7k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </MDBCol>
                </MDBRow>
                <hr />
            </MDBContainer>
        </>
    );

}

export default VideoTutorials;