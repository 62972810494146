import React, { useState, useEffect, useMemo } from 'react';
import Pagination from '../../Pagination/Pagination';
import AdminNav from "../AdminNav";
import { HasAccess } from "@permify/react-role";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CarouselDesktopStyle.css';
import 'react-quill/dist/quill.snow.css';
import Certificate from './Certificate';
import {
    MDBBtn,
    MDBInput,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
} from 'mdb-react-ui-kit';
import axios from "axios";

let PageSize = 10;

export default function Certificates() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [file, setFile] = useState();
    const [files, setFiles] = useState();
    const [fileName, setFileName] = useState();
    const [fileNames, setFileNames] = useState();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [slidesData, setSlidesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [refreshKey, setRefreshKey] = useState(0);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return slidesData.slice(firstPageIndex, lastPageIndex);
    });

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
        getCertificates();
    }, [refreshKey]);

    function getCertificates() {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + 'Certificates/GetCertificates',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setSlidesData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        console.log("table data: ", currentTableData);
    }

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav',
        centerMode: true,
        lazyLoad: 'ondemand',
    };


    const saveFile = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    const selectFiles = (e) => {
        let images = [];
        let names = [];

        for (let i = 0; i < e.target.files.length; i ++){
            images.push(e.target.files[i]);
            names.push(e.target.files[i].name);
        }

        setFiles(images);
        setFileNames(names);
        console.log("images: " + images);
        console.log("names: " + names);
    }

    const uploadFile = async (e) => {
        var FormData = require('form-data');
        console.log(file);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FormFile", file);
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'File/upload',
                headers: {
                    'Authorization': 'Bearer ' + user?.message
                },
                data: formData
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (ex) {
            console.log(ex);
        }
    }

    const uploadFiles = async (e) => {
        var FormData = require('form-data');
        console.log(files);
        const formData = new FormData();


        files.forEach(element => {
            formData.append("FormFile", element);
        });

        fileNames.forEach(name => {
            formData.append("FileName", name);
        });

        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'File/uploadFiles',
                headers: {
                    'Authorization': 'Bearer ' + user?.message
                },
                data: formData
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (ex) {
            console.log(ex);
        }
    }

    async function handleSave(fileName) {
        try {
            var data = JSON.stringify({
                "image": fileName
            });

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Certificates/AddCertificate',
                headers: {
                    'Authorization': 'Bearer ' + user?.message,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    uploadFile();
                    setRefreshKey(oldKey => oldKey + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });

            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                console.log('no server response');
            }
            else {
                console.log('update failed')
            }
        }
    }

    async function handleMultipleSave(fileNames) {
        try {
            var data = JSON.stringify(fileNames);

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Certificates/AddCertificates',
                headers: {
                    'Authorization': 'Bearer ' + user?.message,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    uploadFiles();
                    setRefreshKey(oldKey => oldKey + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });

            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                console.log('no server response');
            }
            else {
                console.log('update failed')
            }
        }
    }

    async function deleteCertificate(id) {
        try {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Certificates/DeleteCertificate/' + id,
                headers: {
                    'Authorization': 'Bearer ' + user?.message,
                    'Content-Type': 'application/json'
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setRefreshKey(oldKey => oldKey + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });

            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                console.log('no server response');
            }
            else {
                console.log('delete failed')
            }
        }
    }

    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <MDBContainer fluid>
                    <MDBRow style={{ height: "auto" }}>
                        <MDBCol md='2'>
                            <AdminNav />
                        </MDBCol>
                        <MDBCol md='8'>
                            <h1>Certificates</h1>
                            <hr />
                            <div className='wrapper'>
                                <div className="Carousel" style={{ width: '1000px' }}>
                                    <div className="slider-wrapper">
                                        <Slider
                                            {...settingsMain}
                                            asNavFor={nav2}
                                            ref={slider => (setSlider1(slider))}
                                        >

                                            {slidesData.map((slide) =>

                                                <div className="slick-slide" key={slide.id}>
                                                    <img className="slick-slide-image" src={process.env.REACT_APP_PIC_URL + slide.image} />
                                                </div>

                                            )}

                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Id</th>
                                            <th scope='col'>Image Name</th>
                                            <th scope='col'>Image</th>
                                            <th scope='col'>Action</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {currentTableData.map(item => {
                                            return (
                                                <Certificate
                                                    key={item.id}
                                                    id={item.id}
                                                    Image={item.image}
                                                    deleteCertificate={deleteCertificate} />
                                            );
                                        })}
                                    </MDBTableBody>
                                </MDBTable>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={slidesData.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </MDBCol>
                        <MDBCol md='2'>
                            <div style={{ height: '100px' }}></div>
                            <MDBInput type="file" multiple accept="image/*" onChange={selectFiles} />
                            <br />
                            <MDBBtn type='submit' block onClick={(e) => {
                                e.preventDefault();
                                handleMultipleSave(files, fileNames);
                            }}>
                                Add
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </HasAccess>
        </>
    )
}

