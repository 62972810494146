import React, { useState } from 'react';
import toast, { toastConfig } from 'react-simple-toasts';
import axios from "axios";
import { useEffectOnce } from '../useEffectOnce';

const Score = ({ FullName, score, timer, totalQuestions}) => {
    const [isQuizOver, setIsQuizOver] = useState(true);

    useEffectOnce(() => {
        console.log("running request first time")
        try {
            var FormData = require('form-data');
            const data = new FormData();

            data.append('FullName', FullName);
            data.append('Score', score + "/" + totalQuestions);
            data.append('Time', timer);

            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Mail/quizemail',
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (err) {
            console.log("no server response");
            console.log("error: ", err);
        }
    }, []);


    return (
        <div>
            <div className="card-body">
                <h2 className="card-title">Quiz Completed!</h2>
                <h4 className="card-text" style={{ marginTop: "20px" }}>Your score: {score} / {totalQuestions}</h4>
            </div>
        </div>
    )
}

export default Score;