import React, { useState } from 'react';
import SearchForm from './SearchForm';
import ViewPosts from '../AdminPosts/ViewPosts';
import { HasAccess } from "@permify/react-role";
import AdminNav from "../AdminNav";
import { MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit'; 

const SearchGame = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    console.log('props with single post ', props);

    return (

        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{ height: '100vh' }}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <MDBContainer fluid>
                    <MDBRow style={{ height: "auto" }}>
                        <MDBCol md='2'>
                            <AdminNav />
                        </MDBCol>
                        <MDBCol md='8'>
                            <h1>Welcome {user?.userName}</h1>
                            <hr />
                            <ViewPosts postList={props.postList} onClick={props.updateSinglePost} />
                        </MDBCol>
                        <MDBCol md='2'>
                            <div style={{ height: '85px' }}></div>
                            <SearchForm onSubmit={props.updateSearchResults} />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </HasAccess>
        </>

    );
};

export default SearchGame;