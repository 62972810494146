import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBBtn,
    MDBInput,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBTypography,
    MDBCardGroup    
} from 'mdb-react-ui-kit';
import axios from "axios";
import Service from "./Service";
import Navbar from "../Common/NavBar";
import { useTranslation } from "react-i18next";

function ServiceList() {
    const [serviceList, setServiceList] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        axios
            .get('Service/GetServices')
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setServiceList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <>
            <Navbar />
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('navbar6')}
                </MDBTypography>
            </div>
            <MDBContainer className="my-5 text-center">
                <MDBRow>                  
                        {serviceList.map((service) => {
                            return (
                                <Service
                                    key={service?.id}
                                    name={service?.name}
                                    description={service?.description}
                                    price={service?.price}
                                    Id={service?.id}
                                    Image={service?.imageUrl}
                                    Video={service?.videoUrl}
                                />
                            )
                        })}
                </MDBRow>
            </MDBContainer>
        </>
    );

}

export default ServiceList;