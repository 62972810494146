import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import "./Footer.css"

export default function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <MDBFooter className='text-center text-lg-start text-muted' style={{ paddingTop: 'auto', backgroundColor: '#d2e1d4' }}>
      <div className='text-center p-4 fixed-bottom' style={{ backgroundColor: '#52bf90', color: 'white', position: 'fixed', bottom: '0%', height: "68px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <MDBBtn size='md' href={`tel:${t('phone2')}`} className='mx-6' tag='a' color='dark' floating target='_blank' >
          <MDBIcon fas icon="mobile-alt" />
        </MDBBtn>
        <MDBBtn size='md' href={`https://wa.me/${t('phone1')}`} className='mx-5' tag='a' color='dark' floating target='_blank' s>
          <MDBIcon fab icon="whatsapp" />
        </MDBBtn>
        <MDBBtn size='md' href={`https://maps.app.goo.gl/Z52RZyPHci98gvpKA`} className='mx-6' tag='a' color='dark' floating target='_blank' >
          <MDBIcon fas icon="map-marked-alt" />
        </MDBBtn>
      </div>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>

        </div>

        <div>
          <a href='https://www.youtube.com/@logopedland' target="_blank" className='me-4 text-reset' >
            <MDBIcon fab icon="youtube" size='lg' />
          </a>
          <a href='https://www.instagram.com/logopedland.kz/' target="_blank" className='me-4 text-reset' >
            <MDBIcon fab icon="instagram" size='lg' />
          </a>
          <a href="https://wa.me/77084892614" target="_blank" className='me-4 text-reset'>
            <MDBIcon fab icon="whatsapp" size='lg' />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                Logoped Land
              </h6>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t('usefullinks')}</h6>
              <p>
                <a href='/posts' className='text-reset'>
                  {t('navbar3')}
                </a>
              </p>
              <p>
                <a href='/employees' className='text-reset'>
                  {t('navbar4')}
                </a>
              </p>
              <p>
                <a href='/ServiceList' className='text-reset'>
                  {t('navbar6')}
                </a>
              </p>
              <p>
                <a href='/certificates' className='text-reset'>
                  {t('navbar5')}
                </a>
              </p>
              <p>
                <a href='/reviews' className='text-reset'>
                  {t('navbar7')}
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>{t('contactustitle')}</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                {t('address')}
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <a href={`mailto:${t('email')}`} style={{ textDecoration: "none", color: "GrayText" }} target='_blank'>
                  {t('email')}
                </a>
              </p>
              <p>
                <MDBIcon icon="mobile" className="me-3" />
                <a href={`tel:${t('phone2')}`} style={{ textDecoration: "none", color: "GrayText" }} target='_blank'>
                  {t('phone2')}
                </a>
              </p>
              <p>
                <MDBIcon fab icon="whatsapp" className="me-3" />
                <a href={`https://wa.me/${t('phone1')}`} style={{ textDecoration: "none", color: "GrayText" }} target='_blank'>
                  +{t('phone1')}
                </a>
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />
                <a href={`tel:${t('phone3')}`} style={{ textDecoration: "none", color: "GrayText" }} target='_blank'>
                  {t('phone3')}
                </a>
              </p>
              <br />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: '#52bf90', color: 'white', marginBottom: "68px" }}>
        © 2015-{new Date().getFullYear()} All rights reserved. Логопед Ленд Казахстан.
      </div>
    </MDBFooter>
  );
}