import { Routes, Route, BrowserRouter, useParams, useLocation } from 'react-router-dom';
import Header from './components/Common/Header';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Certificates from './components/Certificates/Certificates';
import Employees from './components/Employees/Employees';
import Programms from './components/Programms/Programms';
import Reviews from './components/Reviews/Reviews';
import Footer from './components/Common/Footer';
import AdminDashboard from './components/Admin/AdminDashboard';
import Users from './components/Admin/AdminUsers/Users';
import AddUser from './components/Admin/AdminUsers/AddUser';
import EmployeeList from './components/Admin/AdminEmployees/EmployeeList';
import EditEmployee from './components/Admin/AdminEmployees/EditEmployee';
import AddEmployee from './components/Admin/AdminEmployees/AddEmployee';
import AddPost from './components/Admin/AdminPosts/AddPost';
import { useState, useEffect } from 'react';
import { PermifyProvider } from "@permify/react-role";
import OnePost from './components/ViewPost/OnePost';
import EditPost from './components/Admin/AdminPosts/EditPost';
import DeletePost from './components/Admin/AdminPosts/DeletePost';
import SearchPost from './components/Admin/AdminPosts/SearchPost';
import ServiceList from './components/Pricing/ServiceList';
import Services from './components/Admin/AdminService/Services';
import AddService from './components/Admin/AdminService/AddService';
import axios from 'axios';
import NavBar from './components/Common/NavBar';
import PostSearch from './components/Programms/PostSearch';
import AdminCertificates from "./components/Admin/AdminCertificates/Certificates";
import AdminReviewImages from "./components/Admin/AdminReviewImages/AdminReviewImages";
import FastForWord from "./components/FastForWord/index"
import Contacts from './components/Contacts';
import VideoTutorials from './components/FastForWord/VideoTutorails';
import RealityShow from './components/Home/RealityShow';
import RITMTherapy from './components/Home/RITMTherapy';
import CircadianRhythm from './components/Home/RITM/CircadianRhythm';
import MusicalRhythm from './components/Home/RITM/MusicalRhythm';
import BasalGanglia from './components/Home/RITM/BasalGanglia';
import NeuronMechanism from './components/Home/RITM/NeuronMechanism';
import SpeechAndMusic from './components/Home/RITM/SpeechAndMusic';
import VestebulyarnayaSystema from './components/Home/RITM/VestebulyarnayaSystema';
import Quiz from  './components/Quiz/Home'

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [searchPhrase, setSearchPhrase] = useState('');
  const [postList, setPostList] = useState([]);
  const [currentlySelectedPostId, setCurrentlySelectedPostId] = useState(0);
  let refresh = false;

  const loadPosts = async () => {
    /*var config = {
      method: 'get',
      url: 'https://api.logopedland.kz/api/BlogPost/GetBlogPosts',
      headers: {}
    };*/

    axios
      .get('BlogPost/GetBlogPosts')
      .then(function (response) {
        setPostList(response.data);
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    loadPosts();
  }, [refresh]);

  const updateSearchResults = (phrase) => {
    //console.log('phrase is ' + phrase);
    setSearchPhrase(phrase);
  };

  const updateSinglePost = (id, navigate, uri) => {
    //console.log('Update Single Post = ', id);
    //console.log('Update Single Post = ', navigate);
    var indexNumber = 0;
    for (var i = 0; i < postList.length; i++) {
      if (postList[i].id === id) {
        indexNumber = i;
      }
    }

    setCurrentlySelectedPostId(indexNumber);
    let path = uri + indexNumber;
    //console.log('path ', path);
    navigate(path);
  };

  //console.log("PostList in Appjs", postList);
  const renderedList = postList.filter((post) => {
    if (
      post.title.toLowerCase().includes(searchPhrase.toLowerCase()) ||
      searchPhrase === ''
    ) {
      return true;
    }
    return false;
  });

  //console.log('rendered list ', renderedList);
  //console.log('list ', postList);

  const onEditPost = (navigate) => {
    loadPosts();
    navigate("/");
  };

  const onDeletePost = (navigate) => {
    loadPosts();
    navigate("/");
  };

  return (
    <>
      <PermifyProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="certificates" element={<Certificates />} />
          <Route path="fastforword" element={<FastForWord />} />
          <Route path='videotutorials' element={<VideoTutorials />} />
          <Route path="employees" element={<Employees />} />
          <Route path="programms" element={<Programms />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path='realityshow' element={<RealityShow />} />
          <Route path='circadianrhythm' element={<CircadianRhythm />} />
          <Route path='ritmtherapy' element={<RITMTherapy />} />
          <Route path='musicalrhythm' element={<MusicalRhythm />} />
          <Route path='basalganglia' element={<BasalGanglia />} />
          <Route path='neuronmechanism' element={<NeuronMechanism />} />
          <Route path='speechandmusic' element={<SpeechAndMusic />} />
          <Route path='vestebulyarnayasystema' element={<VestebulyarnayaSystema />} />
          <Route path="admindashboard" element={<AdminDashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="adduser" element={<AddUser />} />
          <Route path="employeelist" element={<EmployeeList />} />
          <Route path="editemployee" element={<EditEmployee />} />
          <Route path="addemployee" element={<AddEmployee />} />
          <Route path="addpost" element={<AddPost />} />
          <Route path="quiz" element={<Quiz />} />
          <Route
            exact
            path='/viewposts'
            element={
              <SearchPost
                updateSearchResults={updateSearchResults}
                postList={renderedList}
                updateSinglePost={updateSinglePost}
              />
            }
          />
          <Route
            exact
            path='/posts'
            element={
              <PostSearch
                updateSearchResults={updateSearchResults}
                postList={renderedList}
                updateSinglePost={updateSinglePost}
              />
            }
          />
          <Route exact path='/page/:id' element={<OnePost post={postList[currentlySelectedPostId]} />} />
          <Route exact path='/edit/:id' element={<EditPost onEditPost={onEditPost} post={postList[currentlySelectedPostId]} />} />
          <Route exact path='/delete/:id' element={<DeletePost onDeletePost={onDeletePost} post={postList[currentlySelectedPostId]} />} />
          <Route path="servicelist" element={<ServiceList />} />
          <Route path="services" element={<Services />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="addservice" element={<AddService />} />
          <Route path="admincertificates" element={<AdminCertificates />} />
          <Route path="adminreviewimages" element={<AdminReviewImages />} />
        </Routes>
        <Footer />
      </PermifyProvider>
    </>
  );
}

export default App;