import { MDBBtn } from 'mdb-react-ui-kit';

export default function Post(props) {
    const handleButtonClick = (id, uri) => {
        console.log('ID clicked ' + id);
        props.onClick(id, uri);
    };

    return (
        <>
            <tr>
                <td>{props.title}</td>
                <td>
                    <MDBBtn color='link' rounded size='sm' onClick={() => handleButtonClick(props.Id, '/page/')}>
                        View
                    </MDBBtn>
                    
                    <MDBBtn color='link' rounded size='sm' onClick={() => handleButtonClick(props.Id, '/edit/')}>
                        Edit
                    </MDBBtn>

                    <MDBBtn color='danger' rounded size='sm' onClick={() => handleButtonClick(props.Id, '/delete/')}>
                        Delete
                    </MDBBtn>
                </td>
            </tr>
        </>
    );
}