import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import axios from "axios";
import ReactGA from 'react-ga4';

const container = document.getElementById("root");
const root = createRoot(container);
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactGA.initialize("G-5G3YDNWY8V");

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading....">
      <Router>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </Router>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
