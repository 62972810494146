import React, { useState } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
} from 'mdb-react-ui-kit';

export default function EditEmployee(props) {
    const [centredModal, setCentredModal] = useState(false);
    const [fullName, setFullName] = useState(props.FullName);
    const [email, setEmail] = useState(props.Email);
    const [title, setTitle] = useState(props.Title);
    const [PhoneNumber, setPhone] = useState(props.PhoneNumber);
    const [Image, setImage] = useState(props.Image);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const toggleShow = () => setCentredModal(!centredModal);

    

    return (
        <>
            <MDBBtn onClick={toggleShow}>Update</MDBBtn>

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Employee</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput wrapperClass='mb-4 mx-1' label='Full Name' id='form1' type='text' size='lg' value={fullName} onChange={(e)=> {setFullName(e.target.value)}}/>
                            <MDBInput wrapperClass='mb-4 mx-1' label='Email' id='form2' type='email' size='lg' value={email} onChange={(e)=> {setEmail(e.target.value)}}/>
                            <MDBInput wrapperClass='mb-4 mx-1' label='Title' id='form3' type='text' size='lg' value={title} onChange={(e)=> {setTitle(e.target.value)}}/>
                            <MDBInput wrapperClass='mb-4 mx-1' label='Title' id='form3' type='text' size='lg' value={PhoneNumber} onChange={(e)=> {setPhone(e.target.value)}}/>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={(e) => {
                                e.preventDefault();
                                window.location.reload(true);
                                props.updateEmployee(props.Id, fullName, email, title, PhoneNumber, Image);
                            }}>Save changes</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}