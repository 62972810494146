import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import {
    MDBRow,
    MDBContainer,
    MDBTypography
} from 'mdb-react-ui-kit';
import Post from "./Post";
import NavBar from '../Common/NavBar';

function Posts(props) {
    const handleSelectionOne = (Id, uri) => {
        console.log('Selected ID is ' + Id);
        props.onClick(Id, navigator, uri);
    };

    console.log('props postList ', props);
    const navigator = useNavigate();

    return (
        <>
            <MDBContainer className="my-5 text-center">
                <MDBRow>
                    {props.postList.map((post) => {
                        return (
                            <Post
                                key={post?.id}
                                title={post?.title}
                                content={post?.content}
                                Id={post?.id}
                                Image={post?.imageUrl}
                                DescriptionTitle={post?.descriptionTitle}
                                DescriptionContent={post?.descriptionContent}
                                onClick={handleSelectionOne}
                            />
                        )
                    })}
                </MDBRow>
            </MDBContainer>
        </>
    );

}

export default Posts;