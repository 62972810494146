import React, { useState, useEffect } from 'react';
import {
    MDBBtn,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdown,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBIcon,
    MDBContainer,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import logo from '../../pics/logo.png'
import { useTranslation } from 'react-i18next';
import HeaderImg from '../../pics/header_img.png'
import { useNavigate, useLocation } from 'react-router-dom';


const langs = {
    en: 'English',
    ru: 'Русский',
    kz: 'Қазақша'
}

export default function Header() {
    const [navbar, setNavbar] = useState(false);
    const [showNavRight, setShowNavRight] = useState(true);
    const [showBasic, setShowBasic] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    //var bg_image = "url('https://localhost:7184/2S2A6108.jpg')";
    //var bg_path = process.env.REACT_APP_PIC_URL + "2S2A6108.jpg";
    var bg_path = "https://images.pexels.com/photos/1001914/pexels-photo-1001914.jpeg?auto=compress&cs=tinysrgb&w=1600";
    var bg_image = `url('${bg_path}')`;
    //https://localhost:7184/2S2A6108.jpg

    function handleViewProgramms() {
        navigate("/programms", { replace: true });
    }

    return (
        <>
            <MDBNavbar expand='lg' light sticky='true' style={{ backgroundColor: '#52bf90' }}>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='/'>
                        <img
                            src={logo}
                            height='30'
                            alt=''
                            loading='lazy'
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>
                                    {t("navbar1")}
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/fastforword' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar8")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/posts' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar3")}</MDBNavbarLink>
                            </MDBNavbarItem>
                            {/*
                            <MDBNavbarItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle tag='a' className='nav-link' role='button' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>
                                        {t("navbar2")}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem link href='/programms' style={{ color: "#faf0e6", textDecoration: "none" }}>{t("navbar2")}</MDBDropdownItem>
                                        <MDBDropdownItem link href='/posts' style={{ color: "#faf0e6", textDecoration: "none" }}>{t("navbar3")}</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavbarItem>
                            */ }
                            <MDBNavbarItem>
                                <MDBNavbarLink href='/employees' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar4")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/certificates' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar5")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/ServiceList' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar6")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/reviews' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar7")}</MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink href='/contacts' style={{ color: "#faf0e6", textDecoration: "none", fontSize: "17px" }}>{t("navbar9")}</MDBNavbarLink>
                            </MDBNavbarItem>

                        </MDBNavbarNav>

                        <div className='d-flex input-group w-auto'>
                            <MDBNavbarItem className='d-flex justify-content-end'>
                                <a href="https://wa.me/77084892614" style={{ color: "#faf0e6", marginTop: "8px", marginRight: "10px", fontSize: "17px" }} target='_blank'>
                                    <MDBIcon fab icon="whatsapp" />
                                </a>
                                <MDBNavbarLink href={`tel:${t('phone2')}`} style={{ color: "#faf0e6", fontSize: "17px" }}>
                                    7(708)4892614
                                </MDBNavbarLink>
                                <MDBDropdown>
                                    <MDBDropdownToggle color='link' style={{ color: "#faf0e6", fontSize: "15px" }}> <i class={t('flag_code')}></i>{t("lang")}</MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {Object.keys(langs).map((lang) => (
                                            <MDBDropdownItem link type='submit' key={lang} onClick={() => i18n.changeLanguage(lang)} disabled={i18n.resolvedLanguage === lang} style={{ color: 'black' }}>
                                                {langs[lang]}
                                            </MDBDropdownItem>
                                        ))}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavbarItem>
                        </div>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
            <div className='p-5 text-center'
                style={{ height: '500px' }}>
                <div className='mask' style={{ height: '580px', width: 'auto', backgroundSize: 'cover', backgroundImage: bg_image }}>
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', height: 580 }}>
                        <div className='d-flex justify-content-center align-items-center h-100' style={{ marginTop: 100 }}>
                            <div className='text-white'>
                                <h1 className='mb-3'>{t("mainpagetitle")}</h1>
                                <h4 className='mb-3'>{t("mainpagesubtitle")}</h4>
                                <MDBBtn rounded style={{ backgroundColor: 'yellow', color: 'black' }} onClick={handleViewProgramms}>{t("mainpagebutton")}</MDBBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}