import { Sidenav, Nav } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import { useNavigate, useLocation } from "react-router-dom";
import React from 'react';
import "rsuite/dist/rsuite.css";
import {
    LogoutOutlined,
} from "@ant-design/icons";


const styles = {
    width: 240,
    display: 'inline-table',
    marginRight: 10,
    heigth: '100%'
};

const CustomSidenav = ({ appearance, openKeys, expanded, onOpenChange, onExpand, ...navProps }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/"

    function logoutHandler() {
        localStorage.clear();
        navigate(from, { replace: true });
    }

    function handleViewUsers() {
        navigate("/users", { replace: true });
    }

    function handleDashboard() {
        navigate("/admindashboard", { replace: true });
    }
    function handleAddUsers() {
        navigate("/adduser", { replace: true });
    }
    function handleViewAllEmployees() {
        navigate("/employeelist", { replace: true });
    }
    function handleViewAddEmployee() {
        navigate("/addemployee", { replace: true });
    }
    function handleViewAdminPosts() {
        navigate("/viewposts", { replace: true });
    }

    function handleViewAddPosts() {
        navigate("/addpost", { replace: true });
    }

    function handleViewServices() {
        navigate("/services", { replace: true });
    }

    function handleViewReviews() {
        navigate("/adminreviewimages", { replace: true });
    }

    function handleViewCertificates() {
        navigate("/admincertificates", { replace: true });
    }



    return (
        <div style={styles}>
            <Sidenav
                appearance={appearance}
                expanded={expanded}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
            >
                <Sidenav.Body>
                    <Nav {...navProps}>
                        <Nav.Item eventKey="1" active icon={<DashboardIcon />} onClick={handleDashboard}>
                            Dashboard
                        </Nav.Item>
                        <Nav.Menu eventKey="2" title="Users" icon={<GroupIcon />}>
                            <Nav.Item eventKey="2-1" onClick={handleAddUsers}>Add User</Nav.Item>
                            <Nav.Item eventKey="2-2">Edit User</Nav.Item>
                            <Nav.Item eventKey="2-3" onClick={handleViewUsers}>View Users</Nav.Item>
                        </Nav.Menu>
                        <Nav.Menu eventKey="3" title="Posts" icon={<MagicIcon />}>
                            <Nav.Item eventKey="3-1" onClick={handleViewAddPosts}>Add Post</Nav.Item>
                            <Nav.Item eventKey="3-2" onClick={handleViewAdminPosts}>View Posts</Nav.Item>
                        </Nav.Menu>
                        <Nav.Menu eventKey="4" title="Service" icon={<MagicIcon />}>
                            <Nav.Item eventKey="4-1" onClick={handleViewServices}>View Services</Nav.Item>
                        </Nav.Menu>
                        <Nav.Menu eventKey="4" title="Reviews" icon={<MagicIcon />}>
                            <Nav.Item eventKey="4-1" onClick={handleViewReviews}>View Reviews</Nav.Item>
                        </Nav.Menu>
                        <Nav.Menu eventKey="4" title="Certificates" icon={<MagicIcon />}>
                            <Nav.Item eventKey="4-1" onClick={handleViewCertificates}>View Certificates</Nav.Item>
                        </Nav.Menu>
                        <Nav.Menu eventKey="5" title="Employees" icon={<GroupIcon />}>
                            <Nav.Item eventKey="5-1" onClick={handleViewAddEmployee}>Add Employee</Nav.Item>
                            <Nav.Item eventKey="5-2" onClick={handleViewAllEmployees}>View Employees</Nav.Item>
                        </Nav.Menu>
                        <Nav.Item eventKey="6" title="Logout" icon={<LogoutOutlined />} onClick={logoutHandler}>
                            Log out
                        </Nav.Item>
                    </Nav>
                </Sidenav.Body>
                <Sidenav.Toggle onToggle={onExpand} />
            </Sidenav>
        </div>
    );
};

const AdminNav = () => {
    const [activeKey, setActiveKey] = React.useState('1');
    const [openKeys, setOpenKeys] = React.useState(['1', '2', '3', '4', '5', '6']);
    const [expanded, setExpand] = React.useState(true);

    return (
        <>
            <CustomSidenav
                activeKey={activeKey}
                openKeys={openKeys}
                onSelect={setActiveKey}
                onOpenChange={setOpenKeys}
                expanded={expanded}
                onExpand={setExpand}
            />
        </>
    );
};

export default AdminNav;