import React from 'react'
import {
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBCardBody,
} from 'mdb-react-ui-kit';
import EditEmployee from './EditEmployee';
import DeleteEmployee from './DeleteEmployee';

const EmployeeCard = (props) => {
    const handleButtonClick = (event, uri) => {
        console.log('ID clicked ' + props.key);
        props.onClick(props.Id, uri);
    };

    return (
        <MDBCard style={{ borderRadius: '15px' }}>
            <MDBCardBody className="p-4">
                <div className="d-flex text-black">
                    <div className="flex-shrink-0">
                        <MDBCardImage
                            style={{ width: '180px', borderRadius: '10px' }}
                            src={process.env.REACT_APP_PIC_URL + props.Image}
                            alt='Generic placeholder image'
                            fluid />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <MDBCardTitle>{props.FullName}</MDBCardTitle>
                        <MDBCardText>{props.Title}</MDBCardText>
                        <MDBCardText>{props.Email}</MDBCardText>
                        <MDBCardText>{props.PhoneNumber}</MDBCardText>
                        <br />
                        <EditEmployee
                            FullName={props.FullName}
                            Id={props.id}
                            Title={props.Title}
                            Email={props.Email}
                            PhoneNumber={props.PhoneNumber}
                            Image={props.Image}
                            updateEmployee={props.updateEmployee}
                        />
                        <DeleteEmployee
                            Id={props.id}
                            deleteEmployee={props.deleteEmployee}
                        />
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>
    );
}

export default EmployeeCard;