import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import {
    MDBTable,
    MDBTableHead,
    MDBTableBody
} from 'mdb-react-ui-kit';
import Post from "./Post";

function ViewPosts(props) {
    const handleSelectionOne = (Id, uri) => {
        console.log('Selected ID is ' + Id);
        props.onClick(Id, navigator, uri);
    };

    console.log('props postList ', props);
    const navigator = useNavigate();

    return (
        <>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Title</th>
                        <th scope='col'></th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {props.postList.map((post) => {
                        return (
                            <Post
                                key={post?.id}
                                title={post?.title}
                                content={post?.content}
                                Id={post?.id}
                                DescriptionTitle={post?.descriptionTitle}
                                DescriptionContent={post?.descriptionContent}
                                onClick={handleSelectionOne}
                            />
                        )
                    })}
                </MDBTableBody>
            </MDBTable>
        </>
    );

}

export default ViewPosts;