import React, { useState } from 'react';

const Questions = ({ questions,
    handleNextQuestion,
    currentQuestion,
    handleAnswerClick, timer, isLastq }) => {
    const optionIds = ['A', 'B', 'C', 'D'];
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        handleAnswerClick(option.isCorrect);
    };

    return (
        <div className="container mt-3 bg-light">
            <div>
                <div className="card-body">
                    <p className="mt-2 text-warning">
                        Time remaining: {timer}
                    </p>
                    <h4 className="card-text">
                        {questions[currentQuestion].id}{') '}
                        {questions[currentQuestion].questionText}
                    </h4>
                    <div className="list-group">
                        {
                            questions[currentQuestion].answerOptions
                                .map((answerOption, index) => (
                                    <button
                                        key={index}
                                        className={`list-group-item 
                                    list-group-item-action 
                                    mt-2 ${selectedOption === answerOption ? 'active' : ''
                                            }`}
                                        onClick={() => handleOptionClick(answerOption)}
                                        style={{
                                            backgroundColor:
                                                selectedOption === answerOption ?
                                                    'lightblue' : 'white',
                                            border: '1px solid gray'
                                        }}
                                    >
                                        {optionIds[index]}{')'} {answerOption.answerText}
                                    </button>
                                ))}
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <p className="card-title">
                                Question {currentQuestion + 1}
                                of {questions.length}
                            </p>
                        </div>
                        <div className="col">
                            {isLastq ? (
                                <button className="btn btn-primary btn-sm"
                                    onClick={handleNextQuestion}>
                                    Submit
                                </button>

                            ) : (
                                <button className="btn btn-primary btn-sm"
                                    onClick={handleNextQuestion}>
                                    Next Question
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questions;