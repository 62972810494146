import React, { useState } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';

export default function DeleteEmployee(props) {
    const [centredModal, setCentredModal] = useState(false);
    const [id, setId] = useState(props.Id);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const toggleShow = () => setCentredModal(!centredModal);

    

    return (
        <>
            <MDBBtn color='danger' onClick={toggleShow}>Delete</MDBBtn>

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Delete Employee</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn color='danger' onClick={(e) => {
                                e.preventDefault();
                                props.deleteEmployee(props.Id);
                                window.location.reload(true);
                            }}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}