import React from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBRow,
    MDBContainer,
    MDBTypography
} from 'mdb-react-ui-kit';
import Navbar from "../Common/NavBar";
import { useTranslation } from "react-i18next";

function RealityShow() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Navbar />
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    RITM THERAPY
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow style={{ marginBottom: "100px" }}>
                    <MDBTypography style={{ fontSize: "20px" }}>
                        <ul>
                            <li><a href='circadianrhythm'>{t('ritm_cr_title')}</a></li>
                            <li><a href='basalganglia'>{t('ritm_bg_title')}</a></li>
                            <li><a href='speechandmusic'>{t('ritm_mr_title')}</a></li>
                            <li><a href='neuronmechanism'>{t('ritm_nm_title')}</a></li>
                            <li><a href='musicalrhythm'>{t('ritm_sam_title')}</a></li>
                            <li><a href='vestebulyarnayasystema'>{t('ritm_vs_title')}</a></li>
                        </ul>
                    </MDBTypography>
                </MDBRow>
            </MDBContainer>
        </>
    );

}

export default RealityShow;