import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Testimonials.css";

export default class Testimonials extends Component {
    render() {
        return (
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={6100}
                showIndicators={false}
            >
                <div>
                    <img src="https://archcenter.org/wp-content/uploads/2015/01/chernikhov-01.jpg" />
                    <div className="myCarousel">
                        <h3>Андрей Чернихов. Школа для детей-аутистов</h3>
                        <h4>архитектор</h4>
                        <p>
                            Аутизм подвержен реабилитации. От тяжелой стадии можно прийти к средней, от средней - почти к норме.
                        </p>
                    </div>
                </div>

                <div>
                    <img src={process.env.REACT_APP_PIC_URL + "nikita.png"} />
                    <div className="myCarousel">
                        <h3>Н.Щирук</h3>
                        <h4>Аутист</h4>
                        <p>
                            Жить рядом с аутистом невероятно трудно.
                        </p>
                    </div>
                </div>

                <div>
                    <img src="https://www.special-psy.com/wp-content/uploads/2015/11/grandin_2.jpg" />
                    <div className="myCarousel">
                        <h3>Темпл Грэндин</h3>
                        <h4>американка, аутистка</h4>
                        <p>
                            Если бы я могла щелкнуть пальцами и тем самым освободиться от аутизма, я бы не стала этого делать, ибо аутизм – часть моей личности.
                        </p>
                    </div>
                </div>

                <div>
                    <img src="https://grokscience.files.wordpress.com/2010/03/autismstudydonation_1.jpg" />
                    <div className="myCarousel">
                        <h3>Стенли Гринспен</h3>
                        <h4>Доктор медицины (M.D.), психиатр, в Медицинском институте Университета им. Джорджа Вашингтона.</h4>
                        <p>
                            Наибольших успехов в развитии добиваются те дети с особыми потребностями, которые большую часть времени участвуют в здоровом обучающем взаимодействии, подобранном с учетом их индивидуальных подробностей. Поэтому в любой программе помощи центральное место должна занимать семья.
                        </p>
                    </div>
                </div>

                <div>
                    <img src={process.env.REACT_APP_PIC_URL + "irina.png"} />
                    <div className="myCarousel">
                        <h3>И. Аранюш-де-Крупе (Канада, Торонто).</h3>
                        <h4>Логопед-дефектолог и музыкотерапевт. Специалист по аутизму. Практический стаж работы с нестандартными детьми свыше 35 лет.</h4>
                        <p>
                        Замечайте, если с вашим ребёнком что-то не так, как бы то ни было тяжело для вас. Чем быстрее вы обратитесь за помощью, тем лучше".
                        </p>
                    </div>
                </div>

                <div>
                    <img src="https://www.koob.ru/foto/author/6810.jpg" />
                    <div className="myCarousel">
                        <h3>Патрик Сансон (Patrick Sanson)</h3>
                        <h4>французский эксперт, специальный педагог, имеющий 30-летний опыт работы с аутичными детьми.</h4>
                        <p>
                        Представьте себе человека в очках с темными стеклами, в которых есть отверстие с маленькую булавочную головку, сквозь которую можно что-то увидеть. Это и есть восприятие аутиста и нужно прилагать все усилия, чтобы помочь этим детям выйти из мрака.
                        </p>
                    </div>
                </div>

                

                
            </Carousel>
        );
    }
}