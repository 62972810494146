import React from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBRow,
    MDBContainer,
    MDBTypography,
    MDBCol
} from 'mdb-react-ui-kit';
import Navbar from "../Common/NavBar";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';

function RealityShow() {
    const { t, i18n } = useTranslation();

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <>
            <Navbar />
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    Реалити-шоу
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>

                    </MDBCol>
                    <MDBCol>
                        <iframe width="300" height="400" src="https://youtube.com/embed/1MeCmvN64AY?si=o5c2YG9KkJeE64Fn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "70px" }}></iframe>

                    </MDBCol>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <h4 style={{ textAlign: "center", marginTop: "50px" }}>Лиза 2 года</h4>
                        <br />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Dc-3dCXN0bo?si=bQuxQZc9AIu91mCY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                        <hr />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/y_b-UYzteAM?si=nLfB5Xmhh0gQty6f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                        <hr />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/47shfQXXbNA?si=nyxOQDkX-FUOFn6Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                    </MDBCol>
                    <MDBCol>
                        <h4 style={{ textAlign: "center", marginTop: "50px" }}>Мансур и Нияз, 2 года 4 месяца</h4>
                        <br />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/F2Te1cA9nN0?si=-C1671n6LGpHhD-u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                        <hr />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/BRlR79tQdFI?si=-Q2CJJOqRjKkJsE_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                        <hr />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/-43lPAsUxHA?si=ixrcESmUgLikBFVO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ textAlign: "center", marginLeft: "40px" }}></iframe>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{ marginBottom: "100px" }}>
                    <MDBTypography style={{ fontSize: "20px" }}>
                        <p style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px" }}>Декабрь 2023-2024 г.</p>
                        <h4 style={{ textAlign: "center" }}>🌟 Присоединяйтесь к уникальному реалити-шоу и станьте частью великой истории! 🌟</h4>
                        <br />
                        <h4 style={{ marginTop: "50px" }}>🎉 У Вас есть неговорящий малыш с диагнозом РАС в возрасте от 1 до 3 лет?</h4>
                        <br />
                        <p>Мы приглашаем Вас в увлекательное приключение, которое принесет огромную пользу Вам и Вашему ребенку! 🌈</p>
                        <br />
                        <h4>🌟 О чем наше реалити-шоу? 🌟</h4>
                        <br />
                        <p>Мы предлагаем Вам принять участие в нашем проекте, основанном на уникальном авторском методе. Ваш ребенок получит комплекс ежедневных занятий на протяжении года в нашем центре, что позволит сэкономить Ваш семейный бюджет, и при этом получит индивидуальную качественную помощь. Вместе мы сделаем вашего малыша героем настоящего события века! 🌠</p>
                        <br />
                        <h4>🌟 Требования для участия: 🌟</h4>
                        <br />
                        <ul>
                            <li>Ваш ребенок имеет диагноз РАС и возраст от 1 до 3 лет.</li>
                            <li>Готовность распространить информацию о проекте в СМИ.</li>
                            <li>Готовность делиться ежедневными успехами и изменениями Вашего малыша.</li>
                            <li>Предварительно сделать ЭЭГ в бодрствующем и сонном состоянии.</li>
                        </ul>
                        <br />
                        <h4>Не упустите шанс стать частью чего-то великого и вдохновляющего! 🌟</h4>
                        <br />
                        <p>📞 Для записи и получения дополнительной информации, пожалуйста, звоните по номеру: </p> <a href="https://wa.me/77084892614">8 708 489 26 14</a>
                        <br />
                        <p>Помогите своему ребенку стать звездой этой удивительной истории!</p>
                    </MDBTypography>
                </MDBRow>
            </MDBContainer>
        </>
    );

}

export default RealityShow;