import { MDBBtn } from 'mdb-react-ui-kit';

export default function Certificate(props) {

    return (
        <>
            <tr>
                <th scope='row'>{props.id}</th>
                <td>{props.Image}</td>
                <td><img src={process.env.REACT_APP_PIC_URL + props.Image} style={{ maxHeight: "150px" }}></img></td>
                <td>
                    <MDBBtn color='danger' rounded size='sm' onClick={(e) => {
                        e.preventDefault();
                        props.deleteCertificate(props.id);
                        window.location.reload(true);
                    }}>
                        Delete
                    </MDBBtn>
                </td>
            </tr>
        </>
    );
}