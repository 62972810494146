import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ReviewCarouselDesktopStyle.css';
import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import NavBar from '../Common/NavBar';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function Certificates() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [slidesData, setSlidesData] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
        getReviews();
    }, []);

    async function getReviews() {
        axios
            .get('Reviews/GetReviews')
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setSlidesData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav',
        centerMode: true,
        lazyLoad: 'ondemand',
    };

    const settingsThumbs = {
        slidesToShow: 3,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px',
        arrows: false,
        lazyLoad: 'ondemand',

    };

    return (
        <>
            <NavBar />
            <MDBContainer fluid>
                <div className="p-5 text-center" style={{ marginTop: '58px' }}>
                    <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                        {t("navbar7")}
                    </MDBTypography>
                </div>
                <div className='wrapper'>
                    <div className="Carousel" style={{ width: '1000px' }}>
                        <div className="slider-wrapper">
                            <Slider
                                {...settingsMain}
                                asNavFor={nav2}
                                ref={slider => (setSlider1(slider))}
                            >

                                {slidesData.map((slide) =>

                                    <div className="slick-slide" key={slide.id}>
                                        <img className="slick-slide-image" src={process.env.REACT_APP_PIC_URL + slide.image} />
                                    </div>

                                )}

                            </Slider>
                            <div className="thumbnail-slider-wrap">
                                <Slider
                                    {...settingsThumbs}
                                    asNavFor={nav1}
                                    ref={slider => (setSlider2(slider))}>

                                    {slidesData.map((slide) =>

                                        <div className="slick-slide" key={slide.id}>
                                            <img className="slick-slide-image" src={ process.env.REACT_APP_PIC_URL + slide.image} />
                                        </div>

                                    )}

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottomSeparator' style={{ widht: '100%', height: 180 }}>

                </div>
            </MDBContainer>
        </>
    );
}