import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselDesktop from './CarouselDesktop';
import CarouselMobile from './CarouselMobile';

export default function Certificates() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 900;
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth)
      window.addEventListener("resize", handleWindowResize);
  
      // Return a function from the effect that removes the event listener
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    return width < breakpoint ? <CarouselMobile /> : <CarouselDesktop />;
}