import React, { useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';


const SearchForm = (props) => {
    const [inputText, setInputText] = useState("");

    const handleChangeInput = (event) => {
        setInputText(event.target.value);
        console.log(inputText);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(inputText);
    };

    return (
        <form onSubmit={handleFormSubmit} style={{maxWidth: '200px'}}>
            <MDBInput type='text' placeholder='Enter search term here' onChange={handleChangeInput} />
        </form>
    );
};

export default SearchForm;