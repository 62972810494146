import React from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBRow,
    MDBContainer,
    MDBTypography
} from 'mdb-react-ui-kit';
import Navbar from "../../Common/NavBar";
import { useTranslation } from "react-i18next";

function BasalGanglia() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Navbar />
            <MDBContainer>
                <MDBRow style={{ marginBottom: "100px" }}>
                    <MDBTypography style={{ fontSize: "20px" }}>
                        <div id="preview" class="preview">
                            <div style={{ display: 'none' }}></div>
                            <div>
                                <div data-draggable="true" class="" style={{ position: 'relative' }}>
                                    <section draggable="false" class="container pt-5" data-v-271253ee="">
                                        <section class="mb-10">
                                            <h1 class="fw-bold">{t('ritm_mr_title')}</h1>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: t('ritm_mr_text') }} style={{ fontSize: "20px" }} />
                                        </section>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </MDBTypography>
                </MDBRow>
            </MDBContainer>
        </>
    );

}

export default BasalGanglia;