import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from 'mdb-react-ui-kit';
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function AddEmployee() {
  const [centredModal, setCentredModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const toggleShow = () => setCentredModal(!centredModal);
  const [error, setError] = useState('');
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/EmployeeList";
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  const saveFile = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  const uploadFile = async (e) => {
    var FormData = require('form-data');
    console.log(file);
    const formData = new FormData();
    formData.append("FileName", fileName);
    formData.append("FormFile", file);
    try {
      var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'File/upload',
        headers: { 
          'Authorization': 'Bearer ' + user?.message
        },
        data : formData
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

      //const res = await axios.post(process.env.REACT_APP_API_URL + "file/upload", formData);
      //console.log(res);
    } catch (ex) {
      console.log(ex);
    }
  }

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhone(formattedPhoneNumber);
  };

  //This code will update the employee information using the axios post method and the endpoint in the backend. 
  //The data is converted into JSON format and is sent to the backend. If it is successful, it will navigate to the employee list page.

  async function addEmployee(fullName, email, title, PhoneNumber, file) {
    try {
      var data = JSON.stringify({
        "fullName": fullName,
        "email": "",
        "phone": "",
        "title": title,
        "salary": "1000",
        "image": file
      });

      var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + 'Employee/createemployee',
        headers: {
          'Authorization': 'Bearer ' + user?.message,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          uploadFile();
          navigate(from, { replace: true });
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log('working');
    }
    catch (err) {
      if (!err?.response) {
        console.log('no server response');
      }
      else {
        console.log('update failed')
      }
    }
  }



  return (
    <>
      <MDBBtn onClick={toggleShow}>Add</MDBBtn>

      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Employee</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput wrapperClass='mb-4 mx-1' label='Full Name' id='form1' type='text' size='lg' value={fullName} onChange={(e) => { setFullName(e.target.value) }} />
              <MDBInput wrapperClass='mb-4 mx-1' label='Email' id='form2' type='email' size='lg' value={email} onChange={(e) => { setEmail(e.target.value) }} />
              <MDBInput wrapperClass='mb-4 mx-1' label='Title' id='form3' type='text' size='lg' value={title} onChange={(e) => { setTitle(e.target.value) }} />
              <MDBInput wrapperClass='mb-4 mx-1' label='Phone' id='form3' type='tel' size='lg' value={phoneNumber} onChange={(e) => { handleInput(e) }} />
              <MDBInput type="file" onChange={saveFile} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShow}>
                Close
              </MDBBtn>
              <MDBBtn onClick={(e) => {
                e.preventDefault();
                addEmployee(fullName, email, title, phoneNumber, fileName);
              }}>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}