import {
    MDBCol,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBRipple,
    MDBCardTitle,
    MDBCardText
} from 'mdb-react-ui-kit';

export default function Post(props) {
    const handleButtonClick = (event, uri) => {
        console.log('ID clicked ' + props.Id);
        props.onClick(props.Id, uri);
    };

    return (
        <>
            <MDBCol md="12" lg="4" className="mb-4">
                <MDBCard onClick={() => handleButtonClick(props.Id, '/page/')} className='h-100'>
                    <MDBRipple
                        rippleColor="light"
                        rippleTag="div"
                        className="bg-image rounded hover-zoom"
                    >

                        <MDBCardImage
                            src={process.env.REACT_APP_PIC_URL + props.Image}
                            fluid
                            style={{ height: "270px" }}
                        />
                        <div className="hover-overlay">
                            <div
                                className="mask"
                                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                            ></div>
                        </div>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle>
                            {props.title}
                        </MDBCardTitle>
                        <h6>
                            {props.DescriptionTitle}
                        </h6>
                        <MDBCardText>
                            {props.DescriptionContent}
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </>
    );
}