import { useState } from "react";
import { HasAccess } from "@permify/react-role";
import React from 'react';
import AdminNav from "./AdminNav";
import { Calendar } from "rsuite";


export default function AdminDashboard() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

    return (
        <>
            <HasAccess
                roles={["ADMIN", "OWNER"]}
                renderAuthFailed={
                    <div style={{height: '100vh'}}>
                        <h1>Unauthorized</h1>
                    </div>}>
                <body>
                    <div className="d-flex flex-row">
                        <div class="d-flex align-items-start flex-column">
                            <div className="align-self-start" style={{ marginLeft: "0px", height: "100vh" }}>
                                <AdminNav />
                            </div>
                        </div>
                        <div class="d-flex align-items-start flex-column" style={{ height: '200px', marginRight: '0px' }}>
                            <div className="mb-auto p-2" style={{ height: '200px', marginRight: '0px' }}>
                                <h1>Welcome {user?.userName}</h1>
                            </div>
                            <div className="mb-auto p-2">
                                <Calendar />
                            </div>
                        </div>
                    </div>
                </body>
            </HasAccess>
        </>
    );
}