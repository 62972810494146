import React, { useState } from "react";
import {
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBCol,
    MDBCardBody,
} from 'mdb-react-ui-kit';
import "./Modalstyles.css";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt, BiPointer } from "react-icons/bi";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";

const EmployeeCard = (props) => {
    const [modal, setModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);

    const openModal = () => {
        setModal(!modal);
    };

    const spinner = () => {
        setVideoLoading(!videoLoading);
    };

    return (
        <MDBCol md="9" lg="7" xl="5" className="mt-5">
            <MDBCard style={{ borderRadius: '15px' }} shadow='5'>
                <MDBCardBody className="p-4">
                    <div className="d-flex text-black">
                        <div onClick={openModal} className="" style={{cursor: "pointer"}}>
                                <div className="flex-shrink-0">
                                    <MDBCardImage
                                        style={{ width: '180px', borderRadius: '10px' }}
                                        src={process.env.REACT_APP_PIC_URL + props.Image}
                                        alt='Generic placeholder image'
                                        fluid />
                                </div>
                            {props.Video ? (
                                modal ? (
                                    <section className="modal__bg" >
                                        <div className="modal__align">
                                            <div className="modal__content" modal={modal}>
                                                <IoCloseOutline
                                                    className="modal__close"
                                                    arial-label="Close modal"
                                                    onClick={setModal}
                                                />
                                                <div className="modal__video-align">
                                                    {videoLoading ? (
                                                        <div className="modal__spinner">
                                                            <BiLoaderAlt
                                                                className="modal__spinner-style"
                                                                fadeIn="none"
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <iframe
                                                        className="modal__video-style"
                                                        onLoad={spinner}
                                                        loading="lazy"
                                                        width="800"
                                                        height="500"
                                                        src={props.Video}
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ) : null
                            ) : null}

                        </div>
                        <div className="flex-grow-1 ms-3">
                            <MDBCardTitle>{props.FullName}</MDBCardTitle>
                            <MDBCardText>{props.Title}</MDBCardText>
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </MDBCol >
    );
}

export default EmployeeCard;