import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
    MDBRow,
    MDBContainer,
    MDBTypography,
    MDBCol,
    MDBCard,
    MDBCardImage,
    MDBRipple,
    MDBCardBody,
    MDBCardText,
    MDBCardGroup,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBCardTitle,
    MDBCardHeader,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBBtn,
    MDBCheckbox,
    MDBInput,
    MDBRadio,
    MDBTextArea,
    MDBCardFooter
} from 'mdb-react-ui-kit';
import axios from "axios";
import Navbar from '../Common/NavBar';
import { useTranslation } from "react-i18next";
import "./index.css"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { toastConfig } from 'react-simple-toasts';
import { useNavigate } from 'react-router-dom';

function Index() {
    const { t, i18n } = useTranslation();
    const [justifyActive, setJustifyActive] = useState('tab1');
    const [selectedOption, setSelectedOption] = useState('1');
    const [prevPricePlus, setPrevPricePlus] = useState("");
    const [prevPrice, setPrevPrice] = useState("");
    const [prevPriceEng, setPrevPriceEng] = useState("");
    const [price, setPrice] = useState(30000);
    const [pricePlus, setPricePlus] = useState(150000);
    const [priceEng, setPriceEng] = useState(10000);
    const [formValue, setFormValue] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        childName: '',
        date: '',
        inlineRadio: '',
        fastforword: false,
        clearfluency: false,
        other: false,
        comment: ''
    });
    const [startDate, setStartDate] = useState(new Date());
    const [showDate, setShowDate] = useState(false);
    const [isCheckedFfw, setIsCheckedFfw] = useState(false);
    const [isCheckedEng, setIsCheckedEng] = useState(false);
    const [isCheckedOther, setIsCheckedOther] = useState(false);
    const [toastTheme, setToastTheme] = useState("failure");
    const navigate = useNavigate();

    toastConfig({ theme: toastTheme, position: "bottom-left" });

    const onChangeForm = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
        console.log(formValue);
    };

    const dateHandler = (date) => {
        console.log("value:", date);
        setStartDate(date);
        setFormValue({ ...formValue, date: date.toString() });
        console.log(formValue);
    }

    const radioHandler = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });

        if (e.target.value == "Parent") {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    }

    const handleCheckBox = (e) => {

        if (e.target.name == "fastforword") {
            if (isCheckedFfw) {
                setIsCheckedFfw(false);
            } else {
                setIsCheckedFfw(true);
            }
        } else if (e.target.name == "clearfluency") {
            if (isCheckedEng) {
                setIsCheckedEng(false);
            } else {
                setIsCheckedEng(true);
            }
        } else if (e.target.name == "other") {
            if (isCheckedOther) {
                setIsCheckedOther(false);
            } else {
                setIsCheckedOther(true);
            }
        } else {
            setIsCheckedFfw(false);
            setIsCheckedEng(false);
            setIsCheckedOther(false);
        }

        setFormValue({ ...formValue, [e.target.name]: e.target.checked });
    }


    const onChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        var standardPrice = 30000;
        var standardPlusPrice = 150000;
        var standardEngPrice = 10000;
        var currPrice = standardPrice;
        var currPlusPrice = standardPlusPrice;
        var currEngPrice = standardEngPrice;

        if (value == "1") {
            setPrice(standardPrice);
        } else if (value == "2") {
            setPrevPrice(((standardPrice) * 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPrice = (standardPrice * 2) * 0.95;
            setPrice(currPrice);
        }
        else if (value == "3") {
            setPrevPrice(((standardPrice) * 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPrice = (standardPrice * 3) * 0.9;
            setPrice(currPrice);
        }
        else if (value == "4") {
            setPrevPrice(((standardPrice) * 6).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPrice = (standardPrice * 6) * 0.85;
            setPrice(currPrice);
        } else if (value == "5") {
            setPricePlus(standardPlusPrice);
        } else if (value == "6") {
            setPrevPricePlus(((standardPlusPrice) * 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPlusPrice = (standardPlusPrice * 2) * 0.95;
            setPricePlus(currPlusPrice);
        } else if (value == "7") {
            setPrevPricePlus(((standardPlusPrice) * 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPlusPrice = (standardPlusPrice * 3) * 0.9;
            setPricePlus(currPlusPrice);
        } else if (value == "8") {
            setPrevPricePlus(((standardPlusPrice) * 6).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currPlusPrice = (standardPlusPrice * 6) * 0.85;
            setPricePlus(currPlusPrice);
        } else if (value == "9") {
            setPriceEng(currEngPrice);
        } else if (value == "10") {
            setPrevPriceEng(((standardEngPrice) * 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currEngPrice = (standardEngPrice * 2) * 0.95;
            setPriceEng(currEngPrice);
        } else if (value == "11") {
            setPrevPriceEng(((standardEngPrice) * 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currEngPrice = (standardEngPrice * 3) * 0.9;
            setPriceEng(currEngPrice);
        } else if (value == "12") {
            setPrevPriceEng(((standardEngPrice) * 6).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            currEngPrice = (standardEngPrice * 6) * 0.85;
            setPriceEng(currEngPrice);
        }


    };

    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    const SendUserEmail = () => {
        var FormData = require('form-data');
        const data = new FormData();
        data.append('ToEmail', formValue.email);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + 'Mail/send',
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleFormSubmit = () => {
        try {
            var FormData = require('form-data');
            const data = new FormData();

            data.append('FirstName', formValue.fname);
            data.append('LastName', formValue.lname);
            data.append('Email', formValue.email);
            data.append('Phone', formValue.phone);
            data.append('WhoIsIt', formValue.inlineRadio);
            data.append('ChildName', formValue.childName);
            data.append('ChildBirthDate', formValue.date);
            data.append('FastForWord', formValue.fastforword);
            data.append('ClearFluency', formValue.clearfluency);
            data.append('OtherProgram', formValue.other);
            data.append('Comments', formValue.comment);

            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + 'Mail/formemail',
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    SendUserEmail();
                    setToastTheme("success");
                    toast('Form Submitted!');
                    //Add delay here if necessary
                    navigate(0);
                })
                .catch(function (error) {
                    console.log(error);
                    setToastTheme("failure");
                    toast('Something went wrong try again');
                });
        } catch (err) {
            console.log("no server response");
            console.log("error: ", err);
            setToastTheme("failure");
            toast('Something went wrong try again  catch');
        }
    }

    return (
        <>
            <Navbar />
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    Fast ForWord
                </MDBTypography>
            </div>
            <MDBContainer className="my-5">
                <MDBRow>
                    <img src={process.env.REACT_APP_PIC_URL + 'ffw1.webp'}></img>
                    <img src={process.env.REACT_APP_PIC_URL + 'ffw2.png'}></img>
                </MDBRow>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow>
                    <MDBTypography>
                        <h1 style={{ textAlign: "center" }}>{t('ffw_header1')}</h1>
                        <br />
                        <p style={{ fontSize: "20px" }}>
                            {t('ffw_text1')}
                        </p>
                    </MDBTypography>
                </MDBRow>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <h1 style={{ textAlign: "left" }}>{t('ffw_header2')}</h1>
                    </MDBTypography>
                </MDBRow>
                <MDBRow>
                    <MDBCardGroup>
                        <MDBCard style={{ margin: "10px" }} shadow='5'>
                            <MDBCardImage src={`https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/global/website-icons-lightbulb-function.svg`} alt='...' position='top' />
                            <MDBCardBody>
                                <MDBCardText style={{ fontSize: "20px" }}>
                                    {t('ffw_text2')}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard style={{ margin: "10px" }} shadow='5'>
                            <MDBCardImage src={`https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/global/website-icons-magnifying-glass.svg`} alt='...' position='top' />
                            <MDBCardBody>
                                <MDBCardText style={{ fontSize: "20px" }}>
                                    {t('ffw_text3')}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard style={{ margin: "10px" }} shadow='5'>
                            <MDBCardImage src={`https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/global/website-icons-upward-arrows.svg`} alt='...' position='top' />
                            <MDBCardBody>
                                <MDBCardText style={{ fontSize: "20px" }}>
                                    {t('ffw_text4')}
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCardGroup>
                </MDBRow>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow className='mb-3'>

                </MDBRow>
                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <p style={{ fontSize: "20px" }}>{t('ffw_text5')}</p>
                    </MDBTypography>
                </MDBRow>
                <MDBRow className='mb-6'>

                </MDBRow>
                <MDBRow>
                    <MDBTypography>
                        <h1 style={{ textAlign: "left", marginBottom: "20px" }}>{t('ffw_header3')}</h1>
                    </MDBTypography>
                    <MDBTabs justify className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                {t('ffw_tab1')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                {t('ffw_tab2')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab3')} active={justifyActive === 'tab3'}>
                                {t('ffw_tab3')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab4')} active={justifyActive === 'tab4'}>
                                {t('ffw_tab4')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab5')} active={justifyActive === 'tab5'}>
                                {t('ffw_tab5')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    <MDBTabsContent>
                        <MDBTabsPane show={justifyActive === 'tab1'}><p style={{ fontSize: "20px" }}>{t('ffw_tab_text1')}</p>
                            <img style={{ maxWidth: "60%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/literacy/ffw-product-page_personalization-adaptivity.webp'}></img>
                        </MDBTabsPane>
                        <MDBTabsPane show={justifyActive === 'tab2'}><p style={{ fontSize: "20px" }}>{t('ffw_tab_text2')}</p>
                            <img style={{ maxWidth: "60%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/literacy/ffw-product-page-real-time-corrective-feedback.webp'}></img>
                        </MDBTabsPane>
                        <MDBTabsPane show={justifyActive === 'tab3'}><p style={{ fontSize: "20px" }}>{t('ffw_tab_text3')}</p>
                            <img style={{ maxWidth: "60%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/literacy/ffw-product-page_reporting-and-progress-monitoring.webp'}></img>
                        </MDBTabsPane>
                        <MDBTabsPane show={justifyActive === 'tab4'}><p style={{ fontSize: "20px" }}>{t('ffw_tab_text4')}</p>
                            <img style={{ maxWidth: "60%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/literacy/ffw-product-page_engaging-and-motivating-features.webp'}></img>
                        </MDBTabsPane>
                        <MDBTabsPane show={justifyActive === 'tab5'}><p style={{ fontSize: "20px" }}>{t('ffw_tab_text5')}</p>
                            <img style={{ maxWidth: "60%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/clear-imagery/literacy/ffw-product-page_on-demand-easy-onboarding.webp'}></img>
                        </MDBTabsPane>
                    </MDBTabsContent>
                </MDBRow>

                <MDBRow className='mb-6'>

                </MDBRow>

                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <h1 style={{ textAlign: "left" }}>{t('ffw_header4')}</h1>
                    </MDBTypography>
                </MDBRow>

                <MDBRow className='mb-3'>
                </MDBRow>

                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <p style={{ fontSize: "20px" }}>{t('ffw_text6')}</p>
                    </MDBTypography>
                </MDBRow>
                <MDBRow className='mb-6'>
                </MDBRow>
                <MDBRow className='mb-6' style={{textAlign: "center"}}>
                    <a href="videotutorials">
                        {t('video_tutorials')}
                    </a>
                </MDBRow>
                <MDBRow className='mb-6'>
                </MDBRow>
                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <h1 style={{ textAlign: "left" }}>{t('ffw_header5')}</h1>
                    </MDBTypography>
                </MDBRow>

                <MDBRow className='mb-3'>
                </MDBRow>

                <MDBRow className='mb-3'>
                    <MDBTypography>
                        <p style={{ fontSize: "20px" }}>{t('ffw_text7')}</p>
                    </MDBTypography>
                </MDBRow>

                <MDBRow className='mb-6'>
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        <img style={{ maxWidth: "100%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/ffw/usdoe-logo.webp'}></img>
                    </MDBCol>
                    <MDBCol>
                        <img style={{ maxWidth: "100%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/ffw/ies-logo.webp'}></img>
                    </MDBCol>
                    <MDBCol>
                        <img style={{ maxWidth: "100%" }} src={'https://cdn.carnegielearning.com/cli_theme/_img/www-2020/ffw/digital-promise-logo.webp'}></img>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mb-6'>
                </MDBRow>

                <MDBRow className='mb-6'>
                    <MDBCardGroup>
                        <MDBCard shadow='5' style={{ margin: "10px", borderRadius: "20px" }}>
                            <MDBCardBody>
                                <MDBCardHeader style={{ backgroundColor: "#6FB2D2", textAlign: "center", color: "white", borderRadius: "20px" }}><h2>Standard</h2></MDBCardHeader>
                                <MDBCardTitle style={{ textAlign: "center" }}>{price == 30000 ? '' : <MDBTypography tag='del'>{prevPrice} kzt</MDBTypography>}</MDBCardTitle>
                                <MDBCardTitle style={{ textAlign: "center" }}><h2>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kzt</h2></MDBCardTitle>

                                <select onChange={onChange} className="form-select">
                                    <option value="1">
                                        {t('ffw_sub_30')}</option>
                                    <option value="2">{t('ffw_sub_60')}</option>
                                    <option value="3">{t('ffw_sub_90')}</option>
                                    <option value="4">{t('ffw_sub_180')}</option>
                                </select>
                                <br />
                                <div className='carddescriptiontitle' style={{ backgroundColor: "#6FB2D2", display: 'flex', justify: 'center', alignItems: "center" }}>
                                    <p style={{ color: "white", fontSize: "20px", margin: "auto" }}>Коррекционный блок</p>
                                </div>
                                <MDBCardText>
                                    <br />
                                    <h5>{t('ffw_sub_standard_desc_title')}</h5>
                                    {t('ffw_sub_standard_desc_text')}
                                </MDBCardText>
                                <MDBCardFooter className='text-muted' style={{ marginTop: "15px" }}>
                                    <MDBBtn href="https://shop.logopedland.kz/index.php/product/fast-forword/" target='_blank' style={{ width: "100%", backgroundColor: "#526D82", marginTop: "10px" }}>
                                        {t('ffw_sub_btn_buy')}
                                    </MDBBtn>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard shadow='5' style={{ margin: "10px", borderRadius: "20px" }}>
                            <MDBCardBody>
                                <MDBCardHeader style={{ backgroundColor: "#85C88A", textAlign: "center", color: "white", borderRadius: "20px" }}><h2>Standard+</h2></MDBCardHeader>
                                <MDBCardTitle style={{ textAlign: "center" }}>{pricePlus == 150000 ? '' : <MDBTypography tag='del'>{prevPricePlus} kzt</MDBTypography>}</MDBCardTitle>
                                <MDBCardTitle style={{ textAlign: "center" }}><h2>{pricePlus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kzt</h2></MDBCardTitle>

                                <select onChange={onChange} className="form-select">
                                    <option value="5">
                                        {t('ffw_sub_30')}</option>
                                    <option value="6">{t('ffw_sub_60')}</option>
                                    <option value="7">{t('ffw_sub_90')}</option>
                                    <option value="8">{t('ffw_sub_180')}</option>
                                </select>
                                <br />
                                <div className='carddescriptiontitle' style={{ backgroundColor: "#85C88A", display: 'flex', justify: 'center', alignItems: "center" }}>
                                    <p style={{ color: "white", fontSize: "20px", margin: "auto" }}>Коррекционный блок</p>
                                </div>
                                <MDBCardText>
                                    <br />
                                    <h5>занятия в центре</h5>
                                    {t('ffw_sub_standard_desc_text')}
                                </MDBCardText>
                                <MDBCardFooter className='text-muted' style={{ marginTop: "15px" }}>
                                    <MDBBtn href="https://shop.logopedland.kz/index.php/product/fast-forword-standart-plus/" target='_blank' style={{ width: "100%", backgroundColor: "#526D82", marginTop: "10px" }}>
                                        {t('ffw_sub_btn_buy')}
                                    </MDBBtn>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard shadow='5' style={{ margin: "10px", borderRadius: "20px" }}>
                            <MDBCardBody>
                                <MDBCardHeader style={{ backgroundColor: "#EBD671", textAlign: "center", color: "white", borderRadius: "20px" }}><h2>English</h2></MDBCardHeader>
                                <MDBCardTitle style={{ textAlign: "center" }}>{priceEng == 10000 ? '' : <MDBTypography tag='del'>{prevPriceEng} kzt</MDBTypography>}</MDBCardTitle>
                                <MDBCardTitle style={{ textAlign: "center" }}><h2>{priceEng.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kzt</h2></MDBCardTitle>

                                <select onChange={onChange} className="form-select">
                                    <option value="9">{t('ffw_sub_30')}</option>
                                    <option value="10">{t('ffw_sub_60')}</option>
                                    <option value="11">{t('ffw_sub_90')}</option>
                                    <option value="12">{t('ffw_sub_180')}</option>
                                </select>
                                <br />

                                <div className='carddescriptiontitle' style={{ backgroundColor: "#EBD671", display: 'flex', justify: 'center', alignItems: "center" }}>
                                    <p style={{ color: "white", fontSize: "20px", margin: "auto" }}>Коррекционный блок</p>
                                </div>
                                <MDBCardText>
                                    <br />
                                    <h5>{t('ffw_sub_english_desc_title')}</h5>
                                    <p>{t('ffw_sub_standard_desc_text')}</p>
                                </MDBCardText>
                                <MDBCardFooter className='text-muted' style={{ marginTop: "15px" }}>
                                    <MDBBtn href="https://shop.logopedland.kz/index.php/product/fast-forword-english/" target='_blank' style={{ width: "100%", backgroundColor: "#526D82", marginTop: "10px" }}>
                                        {t('ffw_sub_btn_buy')}
                                    </MDBBtn>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard shadow='5' style={{ margin: "10px", borderRadius: "20px" }}>
                            <MDBCardBody>
                                <MDBCardHeader style={{ backgroundColor: "#526D82", textAlign: "center", color: "white", borderRadius: "20px" }}><h2>Business</h2></MDBCardHeader>
                                <MDBCardText>
                                    <br />
                                    <p>{t('ffw_sub_business_desc_text')}: llk.ffw@gmail.com</p>
                                </MDBCardText>
                                <MDBCardFooter className='text-muted' style={{ marginTop: "15px" }}>
                                    <MDBBtn style={{ width: "100%", backgroundColor: "#526D82" }} href={`mailto:llk.ffw@gmail.com`}>
                                        {t('ffw_sub_business_button_text')}
                                    </MDBBtn>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCardGroup>
                </MDBRow>
                <MDBRow className='mb-6'>
                    <MDBCol size='2'>

                    </MDBCol>
                    <MDBCol size='8'>
                        <MDBRow tag="form" className='g-3'>
                            <MDBCol md="6">
                                <MDBInput
                                    value={formValue.fname}
                                    name='fname'
                                    onChange={onChangeForm}
                                    id='validationCustom01'
                                    required
                                    label={t('ffw_form_label_first_name')}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput
                                    value={formValue.lname}
                                    name='lname'
                                    onChange={onChangeForm}
                                    id='validationCustom02'
                                    required
                                    label={t('ffw_form_label_last_name')}
                                />
                            </MDBCol>

                            <MDBCol md="6">
                                <MDBInput
                                    value={formValue.email}
                                    name='email'
                                    type='email'
                                    onChange={onChangeForm}
                                    id='validationCustom03'
                                    required
                                    label={t('ffw_form_label_email')}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBInput
                                    value={formValue.phone}
                                    name='phone'
                                    type='tel'
                                    onChange={onChangeForm}
                                    id='validationCustom03'
                                    required
                                    label={t('ffw_form_label_phone_number')}
                                />
                            </MDBCol>
                            <MDBCol md="6">
                                <p>{t('ffw_form_label_radio')}</p>
                                <div style={{ marginTop: "10px" }}>
                                    <MDBRadio name='inlineRadio' id='inlineRadio1' value='Parent' label={t('ffw_form_label_radio_1')} inline onChange={radioHandler} required />
                                    <MDBRadio name='inlineRadio' id='inlineRadio2' value='Specialist' label={t('ffw_form_label_radio_2')} inline onChange={radioHandler} required />
                                    <MDBRadio name='inlineRadio' id='inlineRadio3' value='Other' label={t('ffw_form_label_radio_3')} inline onChange={radioHandler} required />
                                </div>
                            </MDBCol>

                            <MDBCol md="6">
                                {showDate ?
                                    <div style={{ marginTop: "10px" }}>
                                        <p>{t('ffw_form_label_child_bday')}</p>
                                        <DatePicker
                                            name='date'
                                            selected={startDate}
                                            value={startDate}
                                            onChange={(date) => dateHandler(date)}
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            style={{ width: "100%" }}
                                            required
                                        />
                                        <MDBInput
                                            value={formValue.childName}
                                            name='childName'
                                            type='text'
                                            onChange={onChangeForm}
                                            id='childName'
                                            required
                                            label={t('ffw_form_label_child_name')}
                                            style={{ marginTop: "10px" }}
                                        />
                                    </div>
                                    : ''}

                            </MDBCol>

                            <MDBCol md="6">
                                <p>{t('ffw_form_label_checkbox')}
                                </p>
                                <div style={{ marginTop: "10px" }} required>
                                    <MDBCheckbox name='fastforword' id='fastforword' label='Fast ForWord' inline checked={isCheckedFfw} onChange={handleCheckBox} />
                                    <MDBCheckbox name='clearfluency' id='clearfluency' label='English (ClearFluency)' inline checked={isCheckedEng} onChange={handleCheckBox} />
                                    <MDBCheckbox name='other' id='other' label='Other' inline checked={isCheckedOther} onChange={handleCheckBox} />
                                </div>
                            </MDBCol>

                            <MDBCol md="12">
                                <div style={{ marginTop: "10px" }}>
                                    <MDBTextArea
                                        id='textAreaExample'
                                        rows={5}
                                        value={formValue.comment}
                                        name='comment'
                                        onChange={onChangeForm}
                                        label={t('ffw_form_label_comments')} />
                                </div>
                            </MDBCol>


                            <MDBCol size="12">
                                <MDBCheckbox label='Agree to terms and conditions' id='invalidCheck2' required />
                            </MDBCol>
                            <MDBCol size="12">
                                <MDBBtn type='submit' onClick={(e) => {
                                    e.preventDefault();
                                    handleFormSubmit();
                                }}>{t('ffw_form_label_btn')}</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size='2'>

                    </MDBCol>
                </MDBRow>
            </MDBContainer >
        </>
    );

}

export default Index;