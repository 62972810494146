import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import qBank from './QuestionBank';
import Questions from './Questions';
import Score from './Score';
import axios from "axios";
import {
    MDBRow,
    MDBContainer,
    MDBTypography,
    MDBCol,
    MDBBtn,
    MDBInput
} from 'mdb-react-ui-kit';

function Home() {
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [timer, setTimer] = useState(100);
    const [quizStarted, setQuizStarted] = useState(false);
    const [isLastq, setIsLastq] = useState(false);
    const [FullName, setFullName] = useState("");


    useEffect(() => {
        axios
            .get('Quiz/GetQuestions')
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setQuestions(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (quizStarted) {
            const interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        setCurrentQuestion(prevQuestion => prevQuestion + 1);
                        return 10;
                    }
                });
            }, 1000);
        }
    }, [currentQuestion, quizStarted]);

    const onChangeForm = (e) => {
        setFullName(e.target.value);
        console.log(FullName);
    };

    const handleAnswerClick = (selectedAnswer) => {
        console.log("answer: " + selectedAnswer);
        if (selectedAnswer) {
            setScore(prevScore => prevScore + 1);
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestion + 2 === questions.length) {
            setIsLastq(true);
        }
        setCurrentQuestion(prevQuestion => prevQuestion + 1);
    }

    const startQuiz = () => {
        setQuizStarted(true);
    };


    return (
        <>
            <div className="p-5 text-center" style={{ marginTop: '70px' }}>
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    Quiz
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>

                    </MDBCol>
                    <MDBCol>
                        <div className="card container mt-2 
         d-flex justify-content-center 
         align-items-center"
                            style={{ maxWidth: "600px", margin: '0 auto', minHeight: "500px" }}>
                            <div>

                                {!quizStarted ? (
                                    <div>
                                        <div className="card-body">
                                            <form
                                                onSubmit={startQuiz}>
                                                <MDBInput
                                                    style={{ marginTop: "20px" }}
                                                    label="Full Name"
                                                    id="fullName"
                                                    name="FullName"
                                                    type="text"
                                                    onChange={onChangeForm}
                                                    required>

                                                </MDBInput>
                                                <MDBBtn className='d-grid gap-2 col-6 mx-auto'
                                                    rounded
                                                    style={{ marginLeft: 'auto', marginTop: "30px", minWidth: "200px" }}>
                                                    Start
                                                </MDBBtn>
                                            </form>
                                        </div>
                                    </div>
                                ) : currentQuestion < questions.length ? (
                                    <Questions
                                        questions={questions}
                                        handleNextQuestion={handleNextQuestion}
                                        currentQuestion={currentQuestion}
                                        handleAnswerClick={handleAnswerClick}
                                        timer={timer}
                                        isLastq={isLastq}
                                    />
                                ) : (
                                    <Score
                                        FullName={FullName.toString()}
                                        score={score}
                                        timer={timer.toString()}
                                        totalQuestions={questions.length}
                                    />
                                )}
                            </div>
                        </div>

                    </MDBCol>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
                <MDBRow style={{ marginBottom: "100px" }}>

                </MDBRow>
            </MDBContainer>
        </>
    );
}

export default Home;