import React from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTypography,
    MDBIcon
} from 'mdb-react-ui-kit';

import NavBar from '../Common/NavBar';
import { useTranslation } from "react-i18next";

export default function Contacts() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <NavBar />
            <div className="p-5 text-center">
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('navbar9')}
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow className='mb-8'>
                    <MDBCol size='md-5'>
                        <MDBRow className='mb-3'>
                            <p><MDBIcon fas icon="home" className="me-3"/>{t('address')}</p>
                        </MDBRow>
                        <MDBRow className='mb-3' >
                            <p><MDBIcon fas icon="calendar-alt" className="me-3"/>{t('business_hours')}</p>
                        </MDBRow>
                        <MDBRow className='mb-3'>
                            <p><MDBIcon icon="mobile" className="me-3" />
                                <a href={`tel:${t('phone2')}`} style={{ textDecoration: "none" }} target='_blank'>
                                    {t('phone2')}
                                </a>
                            </p>
                        </MDBRow>
                        <MDBRow className='mb-3'>
                            <p><MDBIcon icon="envelope" className="me-3" />
                                <a href={`mailto:${t('phone2')}`} style={{ textDecoration: "none" }} target='_blank'>
                                    {t('email')}
                                </a>
                            </p>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size='md-7'>
                        <MDBRow>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.978919597733!2d76.89279857666283!3d43.20993148098645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f2370a11939%3A0x410268c29b65889d!2z0JvQvtCz0L7Qv9C10LQg0JvQtdC90LQg0JrQsNC30LDRhdGB0YLQsNC9!5e0!3m2!1sru!2skz!4v1696324840343!5m2!1sru!2skz" width="auto" height="auto" style={{ minHeight: "400px", minWidth: '300px', border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mb-8'>

                </MDBRow>
            </MDBContainer>
        </>
    );
}