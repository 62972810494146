import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import axios from "axios";
import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon,
    MDBContainer,
    MDBTypography
} from 'mdb-react-ui-kit';
import NavBar from '../Common/NavBar';
import { Link } from "react-router-dom";
import { usePermify } from "@permify/react-role";


const Login = () => {
    const navigate = useNavigate();
    const [userName, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setUser } = usePermify();

    const API = axios.create({
        baseURL: process.env.REACT_APP_API_URL + 'Auth/',
        withCredentials: true,
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await API.post('login', {
                userName, password
            })
                .then(res => {
                    if (res?.data.isSucceed) {
                        const role = res?.data.role;
                        const token = res?.data.message;
                        console.log({ "role": `${role}`, "name": `${userName}`, "token": `${token}` })
                        localStorage.setItem('user', JSON.stringify(res.data));
                        localStorage.setItem('name', userName);
                        localStorage.setItem('token', token);
                        setUser({
                            userName: userName,
                            roles: role,
                            token: token,
                          });            
                        setName('');
                        setPassword('');
                        navigate('/admindashboard', {replace: true});
                    }
                    else {
                        console.log('incorrect submission');
                        setError(res.message);
                    }
                })
            console.log('working');
        }
        catch (err) {
            if (!err?.response) {
                setError('no server response');
            }
            else {
                setError('login failed')
            }
        }

    }
    return (
        <>
            <NavBar />
            <div className="p-5 text-center" style={{ marginTop: '58px' }}>
                    <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                        Sign In
                    </MDBTypography>
                </div>
            <MDBContainer className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <form onSubmit={handleSubmit} style={{ minWidth: "300px" }}>
                    <MDBInput className='mb-4' type='text' id='form2Example1' label='User Name' onChange={(e) => setName(e.target.value)} required />
                    <MDBInput className='mb-4' type='password' id='form2Example2' label='Password' onChange={(e) => setPassword(e.target.value)} required />

                    <MDBBtn type='submit' className='mb-4' block disabled={!userName && !password ? true : false}>
                        Sign in
                    </MDBBtn>

                    <div className='text-center'>
                        <p>
                            Not a member? <a href='register'>Register</a>
                        </p>
                    </div>
                </form>
            </MDBContainer>
        </>
    );
}
export default Login




