import React from 'react';
import SearchForm from './SearchForm';
import Posts from './Posts';
import {
    MDBNavbar,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTypography,
    MDBIcon,
    MDBAccordion,
    MDBAccordionItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBCarousel,
    MDBCarouselItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import pic1 from '../../pics//01.avif'
import pic2 from '../../pics//02.avif'
import pic4 from '../../pics//04.avif'
import pic5 from '../../pics//05.avif'
import pic6 from '../../pics//06.avif'
import pic7 from '../../pics//07.avif'
import pic8 from '../../pics//08.avif'
import NavBar from '../Common/NavBar';
import { useTranslation } from "react-i18next";

const PostSearch = (props) => {
    const { t, i18n } = useTranslation();
    console.log('props with single post ', props);

    return (

        <>
            <NavBar />
            <div className="p-5 text-center">
                <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                    {t('navbar3')}
                </MDBTypography>
            </div>
            <MDBContainer>
                <MDBRow style={{ height: "auto" }}>
                    <MDBCol md='10'>
                        Search: <SearchForm onSubmit={props.updateSearchResults} />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <Posts postList={props.postList} onClick={props.updateSinglePost} />
                </MDBRow>
            </MDBContainer>
        </>

    );
};

export default PostSearch;