import Header from "../Common/Header";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { MDBContainer, MDBTypography } from "mdb-react-ui-kit";
import Navbar from "../Common/NavBar";

export default function OnePost(props) {
  const [post, setPost] = useState(props.post);
  const Id = useParams();
  let refresh = false;
  console.log("id: ", Id);

  useEffect(() => {
    (async () => {
      axios
        .get('BlogPost/GetBlogPosts')
        .then(function (response) {
          setPost(response.data[Id.id]);
        })
        .catch(function (error) {
          console.log(error);
        });
    })();
  }, [refresh]);

  return (
    <>
      <Navbar />
      <MDBContainer className="mt-5">
        {post !== undefined ?
          <div>
            <div className="p-5 text-center" style={{ marginTop: '10px' }}>
              <MDBTypography tag='div' className='display-1 pb-3 mb-3 border-bottom'>
                {post.title}
              </MDBTypography>
            </div>
            <div dangerouslySetInnerHTML={{ __html: post.content }} style={{ fontSize: "20px" }} />
          </div>
          : <div>
            <h1 style={{color: "red"}}>Sorry something went wrong try again</h1>
            </div>}
        <div className="bootomSeparator" style={{ marginTop: '100px' }}>
        </div>
      </MDBContainer>
    </>
  );
}